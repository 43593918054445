<template>
  <b-card class="card-logic-jump">
    <div class="d-flex flex-column mb-2">
      <span class="title">{{ title }}</span>
      <span class="text-description">{{ description }}</span>
    </div>
    <b-row
      class="mb-2"
      no-gutters
      v-for="(logic, index) in logics"
      :key="index"
    >
      <b-col cols="6" no-gutters class="options">
        <span class="pl-3">{{ logic.value }}</span>
      </b-col>
      <b-col cols="6" no-gutters class="pl-1">
        <b-form-select v-model="logic.ref" :options="fieldOptions">
          <template #first>
            <b-form-select-option value="" disabled>
              {{ $t("goToField") }}
            </b-form-select-option>
          </template>
        </b-form-select>
      </b-col>
    </b-row>
  </b-card>
</template>

<i18n>
{
  "en": {
    "goToField": "Go to field"
  },
  "id": {
    "goToField": "Menuju lapangan"
  }
}
</i18n>

<script>
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";

export default {
  props: {
    title: {
      type: String
    },
    description: {
      type: String
    },
    options: {
      type: Array
    },
    fieldLogics: {
      type: Array
    }
  },
  mounted() {
    if (this.fieldLogics && this.fieldLogics.length > 0) {
      this.logics = cloneDeep(this.fieldLogics);
      return;
    }
    if (this.options.length > 0) {
      for (const option of this.options) {
        this.logics.push({ op: "equal_to", value: option.value, ref: "" });
      }
    }
  },
  computed: {
    ...mapGetters("form", ["form", "forms", "selectedVersion"]),
    fieldOptions() {
      return this.selectedForm.fields.map(field => ({
        text: field.title,
        value: field.id
      }));
    }
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler() {
        this.selectedForm = cloneDeep(this.form);
      }
    }
  },
  data() {
    return {
      selectedForm: {},
      logics: []
    };
  }
};
</script>

<style lang="scss" scoped>
.options {
  display: flex;
  align-items: center;
  background-color: #e6e9ed;
  border-radius: 4px;
}
</style>
