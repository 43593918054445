<template>
  <div>
    <p>{{ $t("specificType") }}</p>
    <div class="mb-2">
      <span>{{ $t("image") }}</span>
      <b-form-checkbox
        v-model="fileTypes.image"
        name="required"
        :class="'float-right'"
      >
      </b-form-checkbox>
    </div>
    <div class="mb-2">
      <span>{{ $t("document") }}</span>
      <b-form-checkbox
        v-model="fileTypes.document"
        name="required"
        :class="'float-right'"
      >
      </b-form-checkbox>
    </div>
    <div class="mb-2">
      <span>{{ $t("video") }}</span>
      <b-form-checkbox
        v-model="fileTypes.video"
        name="required"
        :class="'float-right'"
      >
      </b-form-checkbox>
    </div>
    <div class="mb-3">
      <span>{{ $t("others") }}</span>
      <b-form-checkbox
        v-model="fileTypes.other"
        name="required"
        :class="'float-right'"
      >
      </b-form-checkbox>
    </div>
    <b-form-group label="Maximum number of files">
      <b-form-input
        type="number"
        min="1"
        max="10"
        placeholder="Between 1 to 10"
        :value="maxFile"
        @change="updateMaxFile($event)"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Maximum file size">
      <b-form-select :value="maxSize" @change="updateMaxSize($event)">
        <b-form-select-option :value="512000">500 KB</b-form-select-option>
        <b-form-select-option :value="1048576">1 MB</b-form-select-option>
        <b-form-select-option
          v-if="['PROFESSIONAL', 'PREMIUM'].includes(teamLicense)"
          :value="5242880"
          >5 MB</b-form-select-option
        >
        <b-form-select-option
          v-if="['PREMIUM'].includes(teamLicense)"
          :value="10485760"
          >10 MB</b-form-select-option
        >
      </b-form-select>
    </b-form-group>
    <div class="mb-2">
      <span>{{ $t("required") }}</span>
      <b-form-checkbox
        :checked="required"
        @change="toggleRequired"
        name="required"
        class="mb-2"
        switch
        :class="'float-right'"
      >
      </b-form-checkbox>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "required": "Required",
    "specificType": "Only specific file types",
    "image": "Image (jpg, jpeg, png, gif)",
    "document": "Document (pdf, xls, doc, ppt)",
    "video": "Video (mp4, mov)",
    "others": "Any files"
  },
  "id": {
    "required": "Wajib",
    "specificType": "Hanya jenis file tertentu",
    "image": "Gambar (jpg, jpeg, png, gif)",
    "document": "Dokumen (pdf, xls, doc, ppt)",
    "video": "Video (mp4, mov)",
    "others": "Semua file"
  }
}
</i18n>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    fileTypes: {
      type: Object,
      required: true
    },
    maxFile: {
      type: Number,
      required: true
    },
    maxSize: {
      type: Number,
      required: true
    },
    required: {
      type: Boolean
    }
  },
  methods: {
    updateMaxFile($event) {
      this.$emit("update:maxFile", ~~$event);
    },
    updateMaxSize($event) {
      this.$emit("update:maxSize", ~~$event);
    },
    toggleRequired($event) {
      this.$emit("update:required", $event);
    }
  },
  computed: {
    ...mapGetters("team", ["teamLicense"])
  }
};
</script>
