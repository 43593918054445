import formFieldTypes from "@/constants/formFieldTypes";
import formFieldTypeNames from "@/constants/formFieldTypeNames";
import formFieldTypesQuiz from "../constants/formFieldTypesQuiz";

export default quiz => {
  if (!quiz) {
    return Object.values(formFieldTypes)
      .filter(type => type !== formFieldTypes.END_PAGE)
      .map(type => ({
        preview: {
          type,
          typeName: formFieldTypeNames[type]
        },
        node: {
          type,
          typeName: formFieldTypeNames[type]
        }
      }));
  } else {
    return Object.values(formFieldTypesQuiz).map(type => ({
      preview: {
        type,
        typeName: formFieldTypeNames[type]
      },
      node: {
        type,
        typeName: formFieldTypeNames[type]
      }
    }));
  }
};
