<template>
  <div>
    <b-form-group
      class="mt-3 mb-3"
      novalidate
      v-if="type === formFieldTypesQuiz.MULTISELECT_QUIZ"
    >
      <div v-if="!isImage">
        <div
          class="d-flex flex-row justify-content-between align-items-center mb-2"
        >
          <span>{{ $t("choices") }}</span>
          <b-button variant="link" @click="changeToImage" size="sm">{{
            $t("switchImage")
          }}</b-button>
        </div>
        <div v-for="(option, index) in options" :key="index">
          <b-input-group class="mb-2">
            <b-form-input autofocus v-model="option.value"></b-form-input>
            <b-input-group-append is-text @click="deleteOption(index)">
              <b-icon icon="dash-circle-fill" />
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <div v-if="isImage">
        <div
          class="d-flex flex-row justify-content-between align-items-center mb-2"
        >
          <span>{{ $t("choices") }}</span>
          <b-button variant="link" @click="changeToText" size="sm">{{
            $t("switchText")
          }}</b-button>
        </div>
        <div v-for="(option, index) in options" :key="index">
          <b-input-group class="mb-2" v-if="option.file">
            <div class="image-name">
              <div class="icon">
                <icon name="image"></icon>
              </div>
              <div class="name">
                {{ option.file.name }}
              </div>
            </div>
            <b-input-group-append class="cursor-pointer" is-text>
              <div
                class="input-wrapper p-0 mb-0 cursor-pointer"
                style="background: unset;"
              >
                <icon name="reupload-image" />
                <input
                  type="file"
                  class="input-file cursor-pointer"
                  @change="onFileChange($event, index)"
                />
              </div>
            </b-input-group-append>
            <b-input-group-append
              class="cursor-pointer"
              is-text
              @click="deleteOption(index)"
            >
              <b-icon icon="dash-circle-fill" />
            </b-input-group-append>
          </b-input-group>
          <div v-else>
            <div class="input-wrapper">
              <span>{{ $t("imageChoice") }} {{ index + 1 }}</span>
              <input
                type="file"
                class="input-file"
                @change="onFileChange($event, index)"
                :disabled="!option.value"
              />
            </div>
            <span class="text-warning" v-if="!option.value">{{
              $t("fillText")
            }}</span>
          </div>
        </div>
      </div>
      <b-button
        :disabled="options.length >= 4"
        class="p-0"
        variant="link"
        @click="addOption"
        size="sm"
      >
        {{ $t("addChoice") }}
      </b-button>
    </b-form-group>
    <div>
      <p class="mb-2">{{ $t("timeLimit") }}</p>
      <b-form-select
        v-model="timeLimit"
        :options="timeLimitOptions"
        @change="changeTimeLimit($event)"
        class="mb-3"
      >
      </b-form-select>
    </div>
    <div>
      <p class="mb-2">{{ $t("score") }}</p>
      <b-form-select
        v-model="score"
        @change="changeScore($event)"
        :options="scoreOptions"
        class="mb-3"
      >
      </b-form-select>
    </div>
    <div v-if="type === formFieldTypesQuiz.MULTISELECT_QUIZ">
      <p class="mb-2">{{ $t("answerOptions") }}</p>
      <b-form-select
        v-model="answerOptions"
        @change="changeAnswerOptions"
        :options="answerOptionsChoices"
        class="mb-3"
      >
      </b-form-select>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "choices": "Choices",
    "switchImage": "Switch to Image",
    "switchText": "Switch to Text",
    "timeLimit": "Time Limit",
    "score": "Score",
    "answerOptions": "Answer Options",
    "addChoice": "Add choice",
    "label" : "Label",
    "20s": "20 seconds",
    "40s": "40 seconds",
    "60s": "60 seconds",
    "multipleAnswer": "Multiple Answers",
    "singleAnswer": "Single Answer",
    "fillText": "Please fill the text first before you upload image.",
    "imageChoice": "Image for choice"
  },
  "id": {
    "choices": "Pilihan",
    "switchImage": "Beralih ke Gambar",
    "switchText": "Beralih ke Tulisan",
    "timeLimit": "Batas Waktu",
    "score": "Skor",
    "answerOptions": "Opsi Jawaban",
    "addChoice": "Tambah Pilihan",
    "label" : "Label",
    "20s": "20 detik",
    "40s": "40 detik",
    "60s": "60 detik",
    "multipleAnswer": "Banyak Jawaban",
    "singleAnswer": "Satu Jawaban",
    "fillText": "Harap isi teks terlebih dahulu sebelum Anda mengunggah gambar.",
    "imageChoice": "Gambar untuk pilihan"
  }
}
</i18n>

<script>
import formFieldTypesQuiz from "@/constants/formFieldTypesQuiz";
// import { Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

export default {
  data() {
    return {
      option: {
        label: "",
        value: "",
        file: null
      },
      timeLimitOptions: [
        { value: 20, text: this.$t("20s") },
        { value: 40, text: this.$t("40s") },
        { value: 60, text: this.$t("60s") }
      ],
      scoreOptions: [
        { value: 5, text: "5 points" },
        { value: 10, text: "10 points" }
      ],
      answerOptionsChoices: [
        { value: "multiple", text: this.$t("multipleAnswer") },
        { value: "single", text: this.$t("singleAnswer") }
      ],
      isImage: false,
      image: [],
      formFieldTypesQuiz
    };
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    },
    timeLimit: {
      type: Number,
      required: true
    },
    score: {
      type: Number,
      required: true
    },
    answerOptions: {
      type: String,
      required: true
    },
    files: {
      type: Array
    }
  },
  methods: {
    addOption() {
      this.$emit("update:options", [
        ...this.options,
        {
          id: uuidv4(),
          label: this.option.label,
          value: this.option.value,
          file: this.option.file
        }
      ]);
      this.option.label = "";
      this.option.value = "";
    },
    deleteOption(index) {
      this.options.splice(index, 1);
      this.$emit("update:options", this.options);
    },
    changeTimeLimit($event) {
      this.$emit("update:timeLimit", $event);
    },
    changeScore($event) {
      this.$emit("update:score", $event);
    },
    changeAnswerOptions($event) {
      this.$emit("update:answerOptions", $event);
    },
    changeToImage() {
      this.isImage = true;
    },
    changeToText() {
      this.isImage = false;
    },
    onFileChange(e, index) {
      this.$emit("upload-image", { file: e.target.files[0], index });
    }
  }
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  background: #f4f5f7;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 8px;
  display: flex;
  justify-content: center;
  position: relative;

  input[type="file" i] {
    position: absolute;
    opacity: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
  }

  .input-file {
    width: 100%;
    background-color: #f4f5f6;
    padding: 6px 12px;
    position: relative;
  }
}

.image-name {
  width: 70%;
  border: 1px solid #a1aeb7;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  align-items: center;

  .icon {
    width: 15%;
  }

  .name {
    max-width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.input-file {
  opacity: 0%;
  width: 100%;
}

.text-warning {
  font-size: 14px;
  color: red !important;
}
</style>
