<template>
  <b-col v-if="answers.length > 0 && answers[0].answer !== ''">
    <div class="short-text d-flex align-items-center p-3 mb-2">
      {{ answers[0].answer }}
    </div>
  </b-col>
  <b-col v-else>
    <div class="no-answer">No answer</div>
  </b-col>
</template>

<script>
export default {
  props: {
    answers: {
      type: Array
    }
  }
};
</script>

<style lang="scss" scoped>
.short-text {
  background: #edeff2;
  max-height: 36px;
  border-radius: 4px;
}
</style>
