var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-row",staticStyle:{"min-width":"536px"}},[_c('b-col',{staticClass:"p-0 mr-2",staticStyle:{"width":"260px"}},_vm._l((_vm.oddOptions),function(option,index){return _c('b-row',{key:index,staticClass:"mb-2",attrs:{"no-gutters":""}},[_c('b-col',[_c('b-form-group',{staticClass:"mb-0"},[_c('div',{staticClass:"choice-option quiz",class:{
                'correct-answer-option': _vm.isCorrectAnswer(option.value),
                'wrong-answer-option': _vm.isIncorrectAnswer(option.value),
                checked: _vm.isTheAnswer(option.value)
              }},[_c('b-form-checkbox',{attrs:{"checked":_vm.shouldChecked(option.value)}},[_vm._v(" "+_vm._s(option.label)+" ")])],1)])],1)],1)}),1),_c('b-col',{staticClass:"p-0",staticStyle:{"width":"260px"}},_vm._l((_vm.evenOptions),function(option,index){return _c('b-row',{key:index,staticClass:"mb-2",attrs:{"no-gutters":""}},[_c('b-col',[_c('b-form-group',{staticClass:"mb-0"},[_c('div',{staticClass:"choice-option quiz",class:{
                'correct-answer-option': _vm.isCorrectAnswer(option.value),
                'wrong-answer-option': _vm.isIncorrectAnswer(option.value),
                checked: _vm.isTheAnswer(option.value)
              }},[_c('b-form-checkbox',{attrs:{"checked":_vm.shouldChecked(option.value)}},[_vm._v(" "+_vm._s(option.label)+" ")])],1)])],1)],1)}),1)],1),_c('div',[(!_vm.isSingleAnswerCorrect)?_c('span',{staticClass:"text-description"},[_vm._v(_vm._s(_vm.$t("correctAnswer"))+" \""+_vm._s(_vm.correctAnswer === "a" ? "True" : "False")+"\"")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }