<template>
  <div class="wrapper">
    <div class="title mb-1">
      {{ title }}
    </div>
    <div class="color-list">
      <div
        class="color-btn mr-2"
        :style="{ backgroundColor: color }"
        v-for="color in colorList"
        :key="color"
        @click.prevent="selectColor(color)"
      >
        <svg
          v-if="isSelectedColor(color)"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.7819 5.469C20.8518 5.53867 20.9072 5.62143 20.945 5.71255C20.9828 5.80367 21.0023 5.90135 21.0023 6C21.0023 6.09865 20.9828 6.19633 20.945 6.28745C20.9072 6.37857 20.8518 6.46133 20.7819 6.531L10.2819 17.031C10.2123 17.1008 10.1295 17.1563 10.0384 17.1941C9.94728 17.2319 9.8496 17.2513 9.75095 17.2513C9.6523 17.2513 9.55461 17.2319 9.4635 17.1941C9.37238 17.1563 9.28962 17.1008 9.21995 17.031L3.96995 11.781C3.82912 11.6402 3.75 11.4492 3.75 11.25C3.75 11.0508 3.82912 10.8598 3.96995 10.719C4.11078 10.5782 4.30178 10.4991 4.50095 10.4991C4.70011 10.4991 4.89112 10.5782 5.03195 10.719L9.75095 15.4395L19.7199 5.469C19.7896 5.39915 19.8724 5.34374 19.9635 5.30593C20.0546 5.26812 20.1523 5.24866 20.2509 5.24866C20.3496 5.24866 20.4473 5.26812 20.5384 5.30593C20.6295 5.34374 20.7123 5.39915 20.7819 5.469Z"
            :fill="toggleCheckColor(color)"
          />
        </svg>
      </div>
      <div class="color-btn color-picker-toggle" :id="popoverId()">
        <svg
          v-if="true"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967C12.671 5.61032 12.75 5.80109 12.75 6V12C12.75 12.1989 12.671 12.3897 12.5303 12.5303C12.3897 12.671 12.1989 12.75 12 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H11.25V6C11.25 5.80109 11.329 5.61032 11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25Z"
            fill="#F2F5F7"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.25 12C11.25 11.8011 11.329 11.6103 11.4697 11.4697C11.6103 11.329 11.8011 11.25 12 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H12.75V18C12.75 18.1989 12.671 18.3897 12.5303 18.5303C12.3897 18.671 12.1989 18.75 12 18.75C11.8011 18.75 11.6103 18.671 11.4697 18.5303C11.329 18.3897 11.25 18.1989 11.25 18V12Z"
            fill="#F2F5F7"
          />
        </svg>
      </div>
      <b-popover
        :target="popoverId()"
        triggers="click"
        placement="bottomright"
        ref="popover"
        :show.sync="popoverShow"
      >
        <div class="picker-wrapper">
          <ColorPicker @change="saveColor" />
          <div class="text-right mt-3">
            <b-button variant="light" size="sm" @click="toggleColorPicker()"
              >Cancel</b-button
            >
            <b-button
              variant="primary"
              size="sm"
              class="ml-2 mw-68"
              @click="addColor"
              >Add</b-button
            >
          </div>
        </div>
      </b-popover>
    </div>
  </div>
</template>

<script>
import ColorPicker from "./ColorPicker";

export default {
  components: {
    ColorPicker
  },
  props: {
    title: {
      type: String,
      default: "Color"
    },
    selectedColor: {
      type: String,
      default: "#ffffff"
    },
    colorList: {
      type: Array,
      default: () => [
        "#ffffff",
        "#000000",
        "#f28599",
        "#f092ec",
        "#c596eb",
        "#969feb",
        "#96e0eb",
        "#96eba4",
        "#ebdd96",
        "#eba596"
      ]
    }
  },
  data() {
    return {
      colorFromPicker: "",
      popoverShow: false
    };
  },
  methods: {
    isSelectedColor(color) {
      return color === this.selectedColor;
    },
    selectColor(color) {
      this.$emit("update:selectedColor", color);
    },
    saveColor(event) {
      this.colorFromPicker = event.hex;
    },
    addColor() {
      this.$emit("add-color-list", this.colorFromPicker);
      this.toggleColorPicker();
    },
    toggleColorPicker() {
      this.popoverShow = !this.popoverShow;
    },
    popoverId() {
      return this.title.replace(/\s+/g, "-").toLowerCase();
    },
    toggleCheckColor(color) {
      if (color === "#ffffff" || color === "#fff") {
        return "#007BFF";
      } else {
        return "white";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  padding: 16px 24px;
  border-top: 1px solid #dee2e6;
}

.color-btn {
  height: 32px;
  width: 32px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;

  svg {
    display: block;
    margin: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.color-picker-toggle {
  position: relative;
  background-color: #a1aeb7;
}

.mw-68 {
  min-width: 68px;
}

.picker-wrapper {
  width: 188px;
  height: auto;
  background-color: white;
  border-radius: 8px;
  position: relative;
}

.popover {
  border: none;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
}
</style>
