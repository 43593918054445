<template>
  <b-card class="card-logic-jump">
    <div class="d-flex flex-column">
      <span class="title">{{ title }}</span>
      <span class="text-description">{{ description }}</span>
    </div>
    <b-row
      v-for="(logic, index) in logics"
      :key="index"
      no-gutters
      class="mt-3"
    >
      <div class="d-flex align-items-center">
        <div>
          <b-form-select v-model="logic.op" :options="condition">
            <template #first>
              <b-form-select-option value="" disabled>{{
                $t("is")
              }}</b-form-select-option>
            </template>
          </b-form-select>
        </div>
        <div style="width: 190px;">
          <b-form-datepicker
            v-model="logic.value"
            class="dropdown-date"
            :placeholder="$t('chooseDate')"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            }"
          ></b-form-datepicker>
        </div>
        <div style="width: 336px;">
          <b-form-select v-model="logic.ref" :options="fieldOptions">
            <template #first>
              <b-form-select-option value="" disabled>
                {{ $t("goToField") }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </div>
        <div class="ml-2">
          <b-icon
            @click="deleteCondition(index)"
            icon="dash-circle-fill"
            class="cursor-pointer"
            :variant="logics.length > 1 ? 'dark' : 'secondary'"
          ></b-icon>
        </div>
      </div>
    </b-row>
    <b-button @click="addCondition" class="mt-2" size="sm" variant="link">
      {{ $t("addCondition") }}
    </b-button>
  </b-card>
</template>

<i18n>
{
  "en": {
    "is": "is",
    "goToField": "Go to field",
    "addCondition": "Add condition",
    "on": "on",
    "notOn": "not on",
    "before": "before",
    "after": "after",
    "beforeOrOn": "before or on",
    "afterOrOn": "after or on",
    "chooseDate": "Choose date"
  },
  "id": {
    "is": "apakah",
    "goToField": "Menuju lapangan",
    "addCondition": "Tambah kondisi",
    "on": "pada",
    "notOn": "tidak pada",
    "before": "sebelum",
    "after": "setelah",
    "beforeOrOn": "sebelum atau pada",
    "afterOrOn": "setelah atau pada",
    "chooseDate": "Pilih tanggal"
  }
}
</i18n>

<script>
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";

export default {
  props: {
    title: {
      type: String
    },
    description: {
      type: String
    },
    fieldLogics: {
      type: Array
    }
  },
  mounted() {
    if (this.fieldLogics && this.fieldLogics.length > 0) {
      this.logics = cloneDeep(this.fieldLogics);
      return;
    }
    this.logics.push({ op: "", value: "", ref: "" });
  },
  computed: {
    ...mapGetters("form", ["form", "forms", "selectedVersion"]),
    fieldOptions() {
      return this.selectedForm.fields.map(field => ({
        text: field.title,
        value: field.id
      }));
    }
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler() {
        this.selectedForm = cloneDeep(this.form);
      }
    }
  },
  data() {
    return {
      selectedForm: {},
      condition: [
        { value: "on", text: this.$t("on") },
        { value: "not_on", text: this.$t("notOn") },
        { value: "before", text: this.$t("before") },
        { value: "after", text: this.$t("after") },
        { value: "before_or_on", text: this.$t("beforeOrOn") },
        { value: "after_or_on", text: this.$t("afterOrOn") }
      ],
      logics: []
    };
  },
  methods: {
    addCondition() {
      this.logics.push({
        op: "",
        value: "",
        ref: ""
      });
    },
    deleteCondition(index) {
      if (this.logics.length > 1) {
        this.logics.splice(index, 1);
      }
    },
    removeLogic() {
      this.logics = [{ op: "", value: "", ref: "" }];
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown-menu.show {
  width: 288px;
}
</style>
