<template>
  <div>
    <b-col
      md="auto"
      class="d-flex flex-row p-0 align-items-center mb-2"
      v-for="(option, index) in options"
      :key="index"
    >
      <div class="bar-percent">
        <span>{{ optionPercentage(option.value) }}%</span>
      </div>
      <div class="bar-chart-wrapper d-flex align-items-center">
        <span class="value">{{ option.value }}</span>
        <div
          class="bar-chart-progress"
          :style="'width: ' + optionPercentage(option.value) + '%;'"
        ></div>
      </div>
      <div class="bar-result">
        <span>{{ optionCount(option.value) }}</span>
      </div>
    </b-col>
  </div>
</template>

<script>
export default {
  props: {
    answers: {
      type: Array
    },
    options: {
      type: Array
    }
  },
  methods: {
    optionCount(value) {
      let count = 0;
      for (const answer of this.answers) {
        if (answer.answer.includes(value)) {
          count++;
        }
      }
      return count;
    },
    optionPercentage(value) {
      let count = 0;
      if (this.answers.length) {
        for (const answer of this.answers) {
          if (answer.answer.includes(value)) {
            count++;
          }
        }
        return Math.floor((count / this.answers.length) * 100);
      }
      return 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.bar-percent {
  width: 60px;
  text-align: center;
  padding: 6px;
}
.bar-chart-wrapper {
  background: #e8f1fb;
  min-height: 36px;
  height: auto;
  width: 320px;
  position: relative;
}
.bar-chart-progress {
  background: #b9d4f3;
  height: 100%;
  text-align: center;
  padding: 6px;
  overflow: hidden;
  position: absolute;
}
.bar-result {
  width: 32px;
  height: 26px;
  border-radius: 4px;
  background: #e8f1fb;
  color: #3881d7;
  text-align: center;
  padding: 3px;
  font-size: 14px;
  margin-left: 8px;
}
.value {
  padding: 12px;
  z-index: 1;
}
</style>
