<template>
  <div>
    <div class="d-flex flex-row">
      <b-col
        v-for="(value, index) in answers[0].scaleStep"
        :key="index"
        md="auto"
        class="d-flex flex-column justify-content-center p-0 mb-2 mr-1"
      >
        <span class="step">{{ value }}</span>
        <div class="bar-chart-wrapper">
          <div
            class="bar-chart-progress"
            :style="'height: ' + answerPercentage(value) + '%'"
          ></div>
        </div>
        <div class="bar-percent">
          <span>{{ answerPercentage(value) }}%</span>
        </div>
        <div class="bar-result">
          <span>{{ answerCount(value) }}</span>
        </div>
      </b-col>
    </div>
    <div class="label d-flex justify-content-between" v-if="answers.length">
      <span class="label">{{ answers[0].label.left }}</span>
      <span class="label">{{ answers[0].label.right }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    answers: {
      type: Array
    },
    options: {
      type: Array
    }
  },
  data() {
    return {
      values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    };
  },
  methods: {
    answerCount(value) {
      let count = 0;
      for (const answer of this.answers) {
        if (answer.answer === value) {
          count++;
        }
      }
      return count;
    },
    answerPercentage(value) {
      let count = 0;
      if (this.answers.length) {
        for (const answer of this.answers) {
          if (answer.answer === value) {
            count++;
          }
        }
        return Math.floor((count / this.answers.length) * 100);
      } else {
        return 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bar-percent {
  height: 24px;
  width: 48px;
  text-align: center;
  margin-top: 4px;
}
.bar-chart-wrapper {
  background: #e8f1fb;
  height: 180px;
  width: 48px;
  position: relative;
}
.bar-chart-progress {
  background: #b9d4f3;
  height: 50%;
  width: 48px;
  position: absolute;
  bottom: 0;
  overflow: hidden;
}
.bar-result {
  width: 32px;
  height: 26px;
  border-radius: 4px;
  background: #e8f1fb;
  color: #3881d7;
  text-align: center;
  padding: 3px;
  font-size: 14px;
  margin-left: 6px;
}
.step {
  position: absolute;
  top: 5px;
  width: 48px;
  text-align: center;
  z-index: 1;
}
.label {
  color: #5e6674;
  font-size: 14px;
}
</style>
