<template>
  <div>
    <b-form-group label="Scale">
      <div class="d-flex flex-row align-items-center">
        <b-form-input
          class="mr-3"
          style="width: 122px"
          value="1"
          disabled
        ></b-form-input>
        <span>to</span>
        <b-form-select
          @change="updateScaleStep($event)"
          v-model="scaleStep"
          :options="scaleSteps"
          class="ml-3"
          style="width: 122px"
        ></b-form-select>
      </div>
    </b-form-group>
    <b-form-group :label="$t('leftLabel')">
      <b-form-input
        placeholder="Ex: Need improvement..."
        v-model="label.left"
        type="text"
      />
    </b-form-group>
    <b-form-group :label="$t('rightLabel')">
      <b-form-input
        placeholder="Ex: Super great!"
        v-model="label.right"
        type="text"
      />
    </b-form-group>
  </div>
</template>

<i18n>
{
  "en": {
    "leftLabel": "Left label",
    "rightLabel": "Right label"
  },
  "id": {
    "leftLabel": "Label kiri",
    "rightLabel": "Label kanan"
  }
}
</i18n>

<script>
export default {
  props: {
    label: {
      type: Object
    },
    scaleStep: {
      type: Number
    }
  },
  data() {
    return {
      scaleSteps: [5, 6, 7, 8, 9, 10]
    };
  },
  methods: {
    updateScaleStep($event) {
      this.$emit("update:scaleStep", ~~$event);
    }
  }
};
</script>
