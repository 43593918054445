import formFieldTypes from "@/constants/formFieldTypes";
import formFieldTypesQuiz from "@/constants/formFieldTypesQuiz";

const baseFormField = (type, title, description) => {
  return {
    type,
    title: title,
    description: description,
    required: false,
    logics: []
  };
};

const multipleSelectFormField = () => {
  return {
    allowMultipleAnswer: false,
    options: []
  };
};

const welcomeScreenFormField = () => {
  return {
    btnText: "",
    imgUrl: ""
  };
};

const thankYouScreenFormField = () => {
  return {
    shareLinks: {
      qr: false,
      fb: false,
      wa: false,
      es: false
    }
  };
};

const telephoneFormField = () => {
  return {
    country: "all"
  };
};

const scaleFormField = () => {
  return {
    label: {
      left: "",
      right: ""
    },
    scaleStep: 10
  };
};

const uploadFormField = () => {
  return {
    fileTypes: {
      image: true,
      video: false,
      document: false,
      other: false
    },
    maxFile: 1,
    maxSize: 512000
  };
};

const QuizFormField = (type, description) => {
  return {
    type: type,
    description: description,
    logics: [],
    options: [],
    timeLimit: 20,
    score: 5,
    answerOptions: "single",
    correctAnswer: [],
    answerScore: 0,
    maxScore: 0
  };
};

export default (type, title, description) => {
  switch (type) {
    case formFieldTypes.STATEMENT:
      return {
        ...baseFormField(type, title, description)
      };

    case formFieldTypes.START_PAGE:
      return {
        ...baseFormField(type, title, description),
        ...welcomeScreenFormField()
      };

    case formFieldTypes.MULTISELECT:
      return {
        ...baseFormField(type, title, description),
        ...multipleSelectFormField()
      };

    case formFieldTypes.DROPDOWN:
      return {
        ...baseFormField(type, title, description),
        ...multipleSelectFormField()
      };

    case formFieldTypes.END_PAGE:
      return {
        ...baseFormField(type, title, description),
        ...thankYouScreenFormField()
      };

    case formFieldTypes.TEL:
      return {
        ...baseFormField(type, title, description),
        ...telephoneFormField()
      };

    case formFieldTypes.SCALE:
      return {
        ...baseFormField(type, title, description),
        ...scaleFormField()
      };

    case formFieldTypes.FILE_UPLOAD:
      return {
        ...baseFormField(type, title, description),
        ...uploadFormField()
      };

    case formFieldTypesQuiz.MULTISELECT_QUIZ:
      return {
        ...QuizFormField(type, description)
      };

    case formFieldTypesQuiz.TRUE_FALSE:
      return {
        ...QuizFormField(type, description)
      };

    default:
      return {
        ...baseFormField(type, title, description)
      };
  }
};
