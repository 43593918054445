<template>
  <div class="p-4">
    <b-form-select v-model="form.type" :options="typeOptions"></b-form-select>
    <b-form class="mt-4" novalidate @submit.prevent="onSubmit">
      <b-form-group :label="$t('title')">
        <b-form-textarea
          v-model.trim.lazy="form.title"
          :placeholder="$t('insertTitle')"
          required
          rows="3"
          :disabled="isLoading"
        />
        <b-form-invalid-feedback>{{
          $t("titleErrMsg")
        }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group :label="$t('description')" class="mb-0">
        <vue-editor
          :disabled="isLoading"
          v-model="form.description"
          :editor-toolbar="customToolbar"
          :placeholder="$t('insertDesc')"
        ></vue-editor>
      </b-form-group>
      <div class="mb-2">
        <small>{{ $t("maxImgSize") }}</small>
      </div>
      <div v-if="form.type === formFieldTypes.TEL">
        <p class="mb-2">{{ $t("countryCode") }}</p>
        <b-form-select v-model.lazy="form.country" class="mb-3">
          <b-form-select-option :value="'all'">{{
            $t("allCountries")
          }}</b-form-select-option>
          <b-form-select-option
            v-for="(country, index) in filteredCountries"
            :key="index"
            :value="country"
          >
            {{ country.name }} ({{
              country.countryCallingCodes[0]
            }})</b-form-select-option
          >
        </b-form-select>
      </div>
      <FormBuilderScale
        v-if="form.type === formFieldTypes.SCALE"
        :label.sync="form.label"
        :scaleStep.sync="form.scaleStep"
      />
      <div v-if="showRequiredCheckbox">
        <span>{{ $t("required") }}</span>
        <b-form-checkbox
          :checked="form.required"
          name="required"
          class="mb-2"
          switch
          :class="'float-right'"
          @change="toggleRequired"
        >
        </b-form-checkbox>
      </div>
      <b-form-group
        v-if="form.type === formFieldTypes.START_PAGE"
        :label="$t('button')"
      >
        <b-input
          type="text"
          placeholder="Ex: Start survey!"
          v-model.trim.lazy="form.btnText"
        >
        </b-input>
      </b-form-group>
      <FormBuilderMultipleAnswer
        v-if="
          form.type === formFieldTypes.MULTISELECT ||
            form.type === formFieldTypes.DROPDOWN
        "
        :options.sync="form.options"
        :required.sync="form.required"
        :allowMultipleAnswer.sync="form.allowMultipleAnswer"
        :isLoading="isLoading"
        :type="form.type"
      />
      <FormBuilderFileUpload
        v-if="form.type === formFieldTypes.FILE_UPLOAD"
        :fileTypes.sync="form.fileTypes"
        :maxFile.sync="form.maxFile"
        :maxSize.sync="form.maxSize"
        :required.sync="form.required"
      />
      <div class="mt-4 d-flex justify-content-center align-items-center">
        <b-button
          @click="cancel"
          class="mr-2"
          block
          :disabled="isLoading"
          variant="outline-primary"
        >
          <b-icon-arrow-clockwise v-if="isLoading" animation="spin" />
          <span v-else>{{ $t("cancel") }}</span>
        </b-button>
        <b-button
          class="m-0"
          block
          :disabled="isLoading"
          type="submit"
          variant="primary"
        >
          <b-icon-arrow-clockwise v-if="isLoading" animation="spin" />
          <span v-else>{{ $t("save") }}</span>
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<i18n src="@/locales/common/form-field-type-names.json"></i18n>

<i18n>
{
  "en": {
    "title": "Title",
    "titleErrMsg": "Title is required.",
    "description": "Description",
    "image": "Image",
    "chooseImg": "Choose Image",
    "button": "Button",
    "shareLinks": "Share Links",
    "required": "Required",
    "countryCode": "Country Code",
    "cancel" : "Cancel",
    "save": "Save",
    "edit": "Edit",
    "add": "Add",
    "removeLogic": "Remove logic",
    "saveChanges": "Save changes",
    "close": "Close",
    "logicJump": "Logic Jump",
    "insertTitle": "Eg: Welcome to our survey form...",
    "insertDesc": "Eg: This form is to collect your feedback about our latest product...",
    "maxImgSize": "max image size is 100 KB.",
    "allCountries": "All Countries"
  },
  "id": {
    "title": "Judul",
    "titleErrMsg": "Judul harus diisi.",
    "description": "Deskripsi",
    "image": "Gambar",
    "chooseImg": "Pilih Gambar",
    "button": "Button",
    "shareLinks": "Bagikan Tautan",
    "required": "Wajib",
    "countryCode": "Kode Negara",
    "cancel" : "Batal",
    "save": "Menyimpan",
    "edit": "Ubah",
    "add": "Tambah",
    "removeLogic": "Hapus logika",
    "saveChanges": "Simpan perubahan",
    "close": "Tutup",
    "logicJump": "Lompatan logika",
    "insertTitle": "Misalnya: Selamat datang di formulir survei kami...",
    "insertDesc": "Misalnya: Formulir ini untuk mengumpulkan tanggapan Anda tentang produk terbaru kami...",
    "maxImgSize": "maksimal ukuran gambar adalah 100 KB.",
    "allCountries": "Semua negara"
  }
}
</i18n>

<script>
import formFieldTypeNames from "@/constants/formFieldTypeNames";
import formFieldTypes from "@/constants/formFieldTypes";
import FormBuilderScale from "./FormBuilderScale";
import FormBuilderMultipleAnswer from "./FormBuilderMultipleAnswer";
import FormBuilderFileUpload from "./FormBuilderFileUpload";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
const countries = require("country-data").countries;

export default {
  components: {
    VueEditor,
    FormBuilderScale,
    FormBuilderMultipleAnswer,
    FormBuilderFileUpload
  },
  data() {
    return {
      form: {
        title: "",
        description: "",
        type: "START_PAGE",
        required: false,
        logics: [],
        allowMultipleAnswer: false,
        options: [],
        btnText: "",
        imgUrl: "",
        shareLinks: {
          qr: false,
          fb: false,
          wa: false,
          es: false
        },
        country: "all",
        fileTypes: {
          image: true,
          video: false,
          document: false,
          other: false
        },
        maxFile: 1,
        maxSize: 512000,
        label: {
          left: "",
          right: ""
        },
        scaleStep: 10
      },
      typeOptions: [
        {
          value: "START_PAGE",
          text: "Welcome Screen"
        },
        {
          value: "STATEMENT",
          text: "Statement"
        },
        {
          value: "EMAIL",
          text: "Email"
        },
        {
          value: "TEL",
          text: "Tel"
        },
        {
          value: "SCALE",
          text: "Opinion scale"
        },
        {
          value: "MULTISELECT",
          text: "Multiple choice"
        },
        {
          value: "LONG_TEXT",
          text: "Long text"
        },
        {
          value: "SHORT_TEXT",
          text: "Short text"
        },
        {
          value: "DROPDOWN",
          text: "Dropdown"
        },
        {
          value: "DATE",
          text: "Date"
        },
        {
          value: "NUMBER",
          text: "Number"
        },
        {
          value: "FILE_UPLOAD",
          text: "File Upload"
        }
      ],
      formFieldTypeNames,
      formFieldTypes,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        ["clean"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "link"]
      ]
    };
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  validations: {
    form: {
      title: {
        required
      }
    }
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    toggleRequired() {
      this.form.required = !this.form.required;
    },
    async onSubmit() {
      this.$emit("save-new-question", this.filteredFormType);
    }
  },
  computed: {
    ...mapGetters({ formGetter: "form/form" }),

    hasFormFields() {
      return !!this.formGetter.fields && this.formGetter.fields.length > 0;
    },
    hasTitle() {
      return !!this.form.title;
    },
    filteredCountries() {
      return countries.all.filter(
        country => !!country.countryCallingCodes.length
      );
    },
    showRequiredCheckbox() {
      return (
        this.form.type === formFieldTypes.EMAIL ||
        this.form.type === formFieldTypes.TEL ||
        this.form.type === formFieldTypes.SCALE ||
        this.form.type === formFieldTypes.LONG_TEXT ||
        this.form.type === formFieldTypes.SHORT_TEXT ||
        this.form.type === formFieldTypes.DATE ||
        this.form.type === formFieldTypes.NUMBER
      );
    },
    filteredFormType() {
      let filteredForm = {};

      switch (this.form.type) {
        case "START_PAGE":
          filteredForm = {
            type: this.form.type,
            title: this.form.title,
            description: this.form.description,
            required: this.form.required,
            btnText: this.form.btnText,
            imgUrl: this.form.imgUrl
          };
          break;

        case "MULTISELECT":
          filteredForm = {
            type: this.form.type,
            title: this.form.title,
            description: this.form.description,
            required: this.form.required,
            logics: this.form.logics,
            allowMultipleAnswer: this.form.allowMultipleAnswer,
            options: this.form.options
          };
          break;

        case "DROPDOWN":
          filteredForm = {
            type: this.form.type,
            title: this.form.title,
            description: this.form.description,
            required: this.form.required,
            logics: this.form.logics,
            allowMultipleAnswer: this.form.allowMultipleAnswer,
            options: this.form.options
          };
          break;

        case "TEL":
          filteredForm = {
            type: this.form.type,
            title: this.form.title,
            description: this.form.description,
            required: this.form.required,
            logics: this.form.logics,
            country: this.form.country
          };
          break;

        case "SCALE":
          filteredForm = {
            type: this.form.type,
            title: this.form.title,
            description: this.form.description,
            required: this.form.required,
            logics: this.form.logics,
            label: this.form.label,
            scaleStep: this.form.scaleStep
          };
          break;

        case "FILE_UPLOAD":
          filteredForm = {
            type: this.form.type,
            title: this.form.title,
            description: this.form.description,
            required: this.form.required,
            logics: this.form.logics,
            fileTypes: this.form.fileTypes,
            maxFile: this.form.maxFile,
            maxSize: this.form.maxSize
          };
          break;

        default:
          filteredForm = {
            type: this.form.type,
            title: this.form.title,
            description: this.form.description,
            required: this.form.required,
            logics: this.form.logics
          };
          break;
      }

      return filteredForm;
    }
  }
};
</script>
