<template>
  <div class="p-4">
    <div class="font-weight-bold">{{ $t(formFieldTypeNames[form.type]) }}</div>
    <b-form
      class="mt-4"
      novalidate
      @submit.prevent="onSubmit"
      @input="autoSave"
    >
      <b-form-group
        v-if="
          form.type !== formFieldTypesQuiz.MULTISELECT_QUIZ &&
            form.type !== formFieldTypesQuiz.TRUE_FALSE
        "
        :label="$t('title')"
      >
        <b-form-textarea
          v-model.trim.lazy="form.title"
          :placeholder="$t('insertTitle')"
          required
          rows="3"
          :disabled="isLoading"
        />
        <b-form-invalid-feedback>{{
          $t("titleErrMsg")
        }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        :label="
          form.type !== formFieldTypesQuiz.MULTISELECT_QUIZ &&
          form.type !== formFieldTypesQuiz.TRUE_FALSE
            ? $t('description')
            : $t('question')
        "
        class="mb-0"
      >
        <vue-editor
          :disabled="isLoading"
          v-model="form.description"
          :editor-toolbar="customToolbar"
          :editorOptions="editorSettings"
          :placeholder="$t('insertDesc')"
          useCustomImageHandler
          @image-added="handleImageAdded"
        ></vue-editor>
      </b-form-group>
      <div class="mb-2">
        <small>{{ $t("maxImgSize") }}</small>
      </div>
      <div v-if="form.type === formFieldTypes.TEL">
        <p class="mb-2">{{ $t("countryCode") }}</p>
        <b-form-select v-model="form.country" class="mb-3">
          <b-form-select-option :value="'all'">{{
            $t("allCountries")
          }}</b-form-select-option>
          <b-form-select-option
            v-for="(country, index) in filteredCountries"
            :key="index"
            :value="country"
          >
            {{ country.name }} ({{
              country.countryCallingCodes[0]
            }})</b-form-select-option
          >
        </b-form-select>
      </div>
      <FormBuilderScale
        v-if="form.type === formFieldTypes.SCALE"
        :label.sync="form.label"
        :scaleStep.sync="form.scaleStep"
      />
      <div v-if="showRequiredCheckbox">
        <span>{{ $t("required") }}</span>
        <b-form-checkbox
          :checked="form.required"
          name="required"
          class="mb-2"
          switch
          :class="'float-right'"
          @change="toggleRequired"
        >
        </b-form-checkbox>
      </div>
      <b-form-group
        v-if="form.type === formFieldTypes.START_PAGE"
        :label="$t('button')"
      >
        <b-input
          type="text"
          placeholder="Ex: Start survey!"
          v-model.trim.lazy="form.btnText"
        >
        </b-input>
      </b-form-group>
      <FormBuilderMultipleAnswer
        v-if="
          form.type === formFieldTypes.MULTISELECT ||
            form.type === formFieldTypes.DROPDOWN
        "
        :options.sync="form.options"
        :required.sync="form.required"
        :allowMultipleAnswer.sync="form.allowMultipleAnswer"
        :isLoading="isLoading"
        :type="form.type"
      />
      <FormBuilderMultipleQuiz
        v-if="
          form.type === formFieldTypesQuiz.MULTISELECT_QUIZ ||
            form.type === formFieldTypesQuiz.TRUE_FALSE
        "
        :options.sync="form.options"
        :timeLimit.sync="form.timeLimit"
        :score.sync="form.score"
        :answerOptions.sync="form.answerOptions"
        :isLoading="isLoading"
        :type="form.type"
        @upload-image="handleUploadImage"
      />
      <div
        v-if="showLogicJump"
        class="d-flex align-items-center justify-content-between mt-2 col-12 p-0"
      >
        <span>{{ $t("logicJump") }}</span>
        <b-button
          :disabled="!hasFormFields || !hasTitle || !hasOptions"
          v-b-modal.logic-modal
          class="mb-2"
          variant="light"
          size="sm"
        >
          {{ hasLogics ? $t("edit") : $t("add") }}
        </b-button>
      </div>
      <FormBuilderEndPage
        v-if="form.type === formFieldTypes.END_PAGE"
        :shareLinks.sync="form.shareLinks"
      />
      <FormBuilderFileUpload
        :fileTypes.sync="form.fileTypes"
        :maxFile.sync="form.maxFile"
        :maxSize.sync="form.maxSize"
        :required.sync="form.required"
        v-if="form.type === formFieldTypes.FILE_UPLOAD"
      />
      <div class="mt-4 d-flex justify-content-center align-items-center">
        <b-button
          @click="cancel"
          class="mr-2"
          block
          :disabled="isLoading"
          variant="outline-primary"
        >
          <b-icon-arrow-clockwise v-if="isLoading" animation="spin" />
          <span v-else>{{ $t("cancel") }}</span>
        </b-button>
        <b-button
          class="m-0"
          block
          :disabled="isLoading"
          type="submit"
          variant="primary"
        >
          <b-icon-arrow-clockwise v-if="isLoading" animation="spin" />
          <span v-else>{{ $t("save") }}</span>
        </b-button>
      </div>
    </b-form>
    <b-modal
      size="lg"
      centered
      id="logic-modal"
      no-stacking
      body-bg-variant="light"
    >
      <!-- MODAL LOGIC JUMP -->
      <template #modal-title>
        <h5 class="mb-0 col-12">{{ $t("logicJump") }}</h5>
      </template>
      <template #default>
        <component
          :is="form.type"
          :fieldLogics="form.logics"
          :title.sync="form.title"
          :description.sync="form.description"
          :options.sync="form.options"
          ref="logicsTemplate"
        />
      </template>
      <template #modal-footer="{ cancel }">
        <div class="w-100">
          <b-button variant="light" @click="removeLogic()" class="float-left">
            {{ $t("removeLogic") }}
          </b-button>
          <b-button variant="primary" @click="saveLogic()" class="float-right">
            {{ $t("saveChanges") }}
          </b-button>
          <b-button variant="light" @click="cancel()" class="float-right mr-2">
            {{ $t("close") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<i18n src="@/locales/common/form-field-type-names.json"></i18n>

<i18n>
{
  "en": {
    "title": "Title",
    "titleErrMsg": "Title is required.",
    "description": "Description",
    "question": "Question",
    "image": "Image",
    "chooseImg": "Choose Image",
    "button": "Button",
    "shareLinks": "Share Links",
    "required": "Required",
    "countryCode": "Country Code",
    "cancel" : "Cancel",
    "save": "Save",
    "edit": "Edit",
    "add": "Add",
    "removeLogic": "Remove logic",
    "saveChanges": "Save changes",
    "close": "Close",
    "logicJump": "Logic Jump",
    "imageTooLarge": "Image size too large",
    "fileTypeNotSupported": "File type not supported",
    "maxImgSize": "max image size is 100 KB.",
    "insertTitle": "Eg: Welcome to our survey form...",
    "insertDesc": "Eg: This form is to collect your feedback about our latest product...",
    "allCountries": "All Countries"
  },
  "id": {
    "title": "Judul",
    "titleErrMsg": "Judul harus diisi.",
    "description": "Deskripsi",
    "question": "Pertanyaan",
    "image": "Gambar",
    "chooseImg": "Pilih Gambar",
    "button": "Button",
    "shareLinks": "Bagikan Tautan",
    "required": "Wajib",
    "countryCode": "Kode Negara",
    "cancel" : "Batal",
    "save": "Menyimpan",
    "edit": "Ubah",
    "add": "Tambah",
    "removeLogic": "Hapus logika",
    "saveChanges": "Simpan perubahan",
    "close": "Tutup",
    "logicJump": "Lompatan logika",
    "imageTooLarge": "Ukuran gambar terlalu besar",
    "fileTypeNotSupported": "Jenis file tidak di dukung",
    "maxImgSize": "maksimal ukuran gambar adalah 100 KB.",
    "insertTitle": "Misalnya: Selamat datang di formulir survei kami...",
    "insertDesc": "Misalnya: Formulir ini untuk mengumpulkan tanggapan Anda tentang produk terbaru kami...",
    "allCountries": "Semua negara"
  }
}
</i18n>

<script>
import formFieldTypeNames from "@/constants/formFieldTypeNames";
import formFieldTypes from "@/constants/formFieldTypes";
import formFieldTypesQuiz from "@/constants/formFieldTypesQuiz";
import cloneDeep from "lodash/cloneDeep";
import { mapGetters } from "vuex";
import FormBuilderMultipleAnswer from "./FormBuilderMultipleAnswer";
import FormBuilderMultipleQuiz from "./FormBuilderMultipleQuiz.vue";
import FormBuilderEndPage from "./FormBuilderEndPage";
import FormBuilderFileUpload from "./FormBuilderFileUpload";
import FormBuilderScale from "./FormBuilderScale";
import EMAIL from "../SimpleLogicJump/EmailLogic";
import SCALE from "../SimpleLogicJump/ScaleLogic";
import MULTISELECT from "../SimpleLogicJump/MultipleLogic";
import LONG_TEXT from "../SimpleLogicJump/LongTextLogic";
import SHORT_TEXT from "../SimpleLogicJump/ShortTextLogic";
import DROPDOWN from "../SimpleLogicJump/DropdownLogic";
import DATE from "../SimpleLogicJump/DateLogic";
import NUMBER from "../SimpleLogicJump/NumberLogic";
import { VueEditor } from "vue2-editor";
import { ImageDrop } from "quill-image-drop-module";
const countries = require("country-data").countries;
import debounce from "lodash/debounce";

import Quill from "quill";
window.Quill = Quill;

const ImageResize = require("quill-image-resize-module").default;
Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageDrop", ImageDrop);

export default {
  components: {
    FormBuilderMultipleAnswer,
    FormBuilderMultipleQuiz,
    FormBuilderEndPage,
    FormBuilderScale,
    FormBuilderFileUpload,
    EMAIL,
    SCALE,
    MULTISELECT,
    LONG_TEXT,
    SHORT_TEXT,
    DROPDOWN,
    DATE,
    NUMBER,
    VueEditor
  },
  data() {
    return {
      form: {},
      formFieldTypeNames,
      formFieldTypes,
      formFieldTypesQuiz,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        ["clean"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "link"]
      ],
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {}
        }
      }
    };
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    teamId: {
      type: String
    },
    formId: {
      type: String
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(v) {
        this.form = cloneDeep(v);
      }
    },
    form: {
      immediate: true,
      deep: true,
      handler() {
        if (this.form.type === "SCALE" && !this.form.scaleStep) {
          this.form.scaleStep = 10;
        }
      }
    }
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    toggleRequired() {
      this.form.required = !this.form.required;
    },
    removeLogic() {
      this.form.logics = [];
      this.$bvModal.hide("logic-modal");
    },
    saveLogic() {
      this.form.logics = this.$refs.logicsTemplate.logics;
      this.onSubmit();
    },
    async onSubmit() {
      this.$emit("save", this.form);
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      if (file.size > 102400) {
        this.$toast.warning(this.$t("imageTooLarge"));
        return;
      }

      if (
        !["image/jpeg", "image/jpg", "image/png", "image/gif"].includes(
          file.type
        )
      ) {
        this.$toast.warning(this.$t("fileTypeNotSupported"));
        return;
      }

      try {
        this.isLoading = true;
        const res = await this.getSignedUrl(file.name, file.type);
        const data = await res.json();
        await fetch(data.uploadURL, {
          method: "PUT",
          body: file
        });
        Editor.insertEmbed(
          cursorLocation,
          "image",
          this.buildImageLink(data.Key)
        );
        resetUploader();
        this.isLoading = false;
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    async handleUploadImage(payload) {
      const { file, index } = payload;

      if (file.size > 102400) {
        this.$toast.warning(this.$t("imageTooLarge"));
        return;
      }

      if (
        !["image/jpeg", "image/jpg", "image/png", "image/gif"].includes(
          file.type
        )
      ) {
        this.$toast.warning(this.$t("fileTypeNotSupported"));
        return;
      }

      try {
        this.isLoading = true;
        const res = await this.getSignedUrl(file.name, file.type);
        const data = await res.json();
        await fetch(data.uploadURL, {
          method: "PUT",
          body: file
        });
        this.isLoading = false;
        const field = cloneDeep(this.value);
        Object.assign(field.options[index], {
          file: {
            name: file.name,
            type: file.type,
            key: data.Key,
            size: file.size
          }
        });
        this.$emit("update-field", field);
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    buildImageLink(key) {
      return `https://${process.env.VUE_APP_BUCKET_NAME}.s3.${process.env.VUE_APP_AWS_REGION}.amazonaws.com/${key}`;
    },
    async getSignedUrl(fileName, fileType) {
      const result = await fetch(
        `${process.env.VUE_APP_API_ENDPOINT}/files/signed-url`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            teamId: this.teamId,
            formId: this.formId,
            fileType,
            fileName
          })
        }
      );
      return result;
    },
    autoSave: debounce(function() {
      this.$emit("autosave", this.form);
    }, 3000)
  },
  computed: {
    ...mapGetters({ formGetter: "form/form" }),

    filteredCountries() {
      return countries.all.filter(
        country => !!country.countryCallingCodes.length
      );
    },

    showRequiredCheckbox() {
      return (
        this.form.type === formFieldTypes.EMAIL ||
        this.form.type === formFieldTypes.TEL ||
        this.form.type === formFieldTypes.SCALE ||
        this.form.type === formFieldTypes.LONG_TEXT ||
        this.form.type === formFieldTypes.SHORT_TEXT ||
        this.form.type === formFieldTypes.DATE ||
        this.form.type === formFieldTypes.NUMBER
      );
    },
    showLogicJump() {
      return (
        this.form.type === formFieldTypes.EMAIL ||
        this.form.type === formFieldTypes.SCALE ||
        this.form.type === formFieldTypes.MULTISELECT ||
        this.form.type === formFieldTypes.LONG_TEXT ||
        this.form.type === formFieldTypes.SHORT_TEXT ||
        this.form.type === formFieldTypes.DROPDOWN ||
        this.form.type === formFieldTypes.DATE ||
        this.form.type === formFieldTypes.NUMBER
      );
    },
    hasFormFields() {
      return !!this.formGetter.fields && this.formGetter.fields.length > 0;
    },
    hasTitle() {
      return !!this.form.title;
    },
    hasOptions() {
      if (
        this.form.type !== formFieldTypes.MULTISELECT &&
        this.form.type !== formFieldTypes.DROPDOWN
      ) {
        return true;
      } else {
        return this.form.options && this.form.options.length > 0;
      }
    },
    hasLogics() {
      return this.form.logics && this.form.logics.length > 0;
    }
  }
};
</script>
