<template>
  <div>
    <div class="d-flex h-100 border-left">
      <FormSidebarMenu
        @show-form-style-editor="openFormStyleEditor"
        @show-flow-drag="openFlowDrag"
        @show-form-response-setting="openFormResponseSetting"
        :is-show-form-style-editor="showFormStyleEditor"
        :is-show-form-builder-form-field="showFormBuilderFormField"
        :is-show-flow-drag="showFlowDrag"
        :is-show-form-response-setting="showFormResponseSetting"
      />
      <div class="editor-wrapper bg-white">
        <transition name="fade-left">
          <FormStyleEditor
            v-if="showFormStyleEditor"
            @update-design="updateDesign($event)"
            @update-background="updateBackground($event)"
          />
        </transition>
        <transition name="fade-right">
          <FormBuilderFormField
            v-if="showFormBuilderFormField"
            :value="formBuilderFormFieldData.value"
            :is-loading="isSaving"
            :team-id="teamId"
            :form-id="formId"
            @save="onSaveFormField"
            @cancel="openFlowDrag"
            @autosave="onSaveFormField($event, true)"
            @update-end-page="
              updateEndPage({ teamId, workspaceId, formId, design: $event })
            "
            @update-field="onSaveFormField"
          />
        </transition>
        <transition name="fade-right">
          <FormBuilderNewQuestionQuiz
            v-if="showFormBuilderNewQuestion"
            :is-loading="isSaving"
            @save-new-question="onSaveNewQuestion"
            @cancel="openFlowDrag"
          />
        </transition>
        <transition name="fade-left">
          <FormResponseSetting
            :teamId="teamId"
            :workspaceId="workspaceId"
            :formId="formId"
            v-if="showFormResponseSetting"
          />
        </transition>
        <transition name="fade-left">
          <div class="flowy-drag-wrapper p-4" v-if="showFlowDrag">
            <div class="item">
              <h5>
                Editor
              </h5>
            </div>
            <div>
              {{ $t("previewBlock.description") }}
            </div>
            <div class="mt-3 flex-grow-1 overflow-auto">
              <flowy-new-block
                v-for="(block, index) in formFieldBlocks"
                :key="index"
                @drag-start="onDragStartNewBlock"
                @drag-stop="onDragStopNewBlock"
              >
                <template v-slot:preview="{}">
                  <FlowyBlockPreview
                    :type="block.node.type"
                    :type-name="block.preview.typeName"
                  />
                </template>
                <template v-slot:node="{}">
                  <FlowyBlockNode
                    :type="block.node.type"
                    :type-name="block.node.typeName"
                  />
                </template>
              </flowy-new-block>
            </div>
          </div>
        </transition>
      </div>
      <div class="drag-wrapper overflow py-3">
        <b-row class="tab mb-3">
          <b-col>
            <div
              class="tab-select"
              :class="{ active: activeTab === 'questions' }"
              @click="setActiveTab('questions')"
            >
              Questions
            </div>
          </b-col>
        </b-row>
        <template v-if="activeTab === 'questions'">
          <flowy
            :nodes="nodes"
            :beforeMove="beforeMove"
            :beforeAdd="beforeAdd"
            @add="add"
            @move="move"
            @remove="remove"
            @drag-start="onDragStart"
            @open-form-builder="openQuestionField"
            v-if="!showStartDropBox && nodes.length > 1"
          />
          <div
            class="d-flex align-items-center justify-content-center flex-column start-question"
            v-if="!showStartDropBox && nodes.length <= 1"
          >
            <icon name="ready-to-publish" />
            <h4>Start your first question</h4>
            <span>Drag a question type from editor tab to begin.</span>
          </div>
          <dropzone
            @receive="handleFirstDrop"
            v-if="showStartDropBox"
            group="flowy"
          >
            <template #default="scope">
              <div :class="scope">
                <div class="">
                  <div class="start-dropbox">
                    <h5 class="text-primary">Drop here</h5>
                  </div>
                </div>
              </div>
            </template>
          </dropzone>

          <b-row
            style="min-height: 360px; cursor: pointer;"
            no-gutters
            class="mx-auto"
            v-if="selectedForm && nodes.length > 1"
          >
            <b-col md="12">
              <b-card
                class="w-100 h-100"
                @click="
                  openFormBuilderFormField(
                    { ...selectedForm.endPage, type: formFieldTypes.END_PAGE },
                    null
                  )
                "
                :style="{
                  backgroundColor: selectedForm.design.colors.backgroundColor
                }"
              >
                <div
                  class="w-100 h-100 d-flex flex-column justify-content-center align-items-center"
                >
                  <div
                    style="max-width:600px;"
                    class="w-100 h-100 d-flex flex-column justify-content-around align-items-center"
                  >
                    <h3
                      class="mt-2 text-center"
                      :style="{ color: selectedForm.design.colors.titleColor }"
                    >
                      {{ selectedForm.endPage.title }}
                    </h3>
                    <span
                      v-if="selectedForm.endPage.description"
                      class="text-center"
                      :style="{
                        color: selectedForm.design.colors.descriptionColor
                      }"
                      v-html="selectedForm.endPage.description"
                    ></span>
                    <div class="share-wrapper text-center">
                      <p
                        class="share"
                        v-if="showShareLinks"
                        :style="{
                          color: selectedForm.design.colors.descriptionColor
                        }"
                      >
                        Share to your friends
                      </p>
                      <div class="share-links">
                        <div
                          class="share-btn"
                          v-if="selectedForm.endPage.shareLinks.qr"
                          :style="{
                            backgroundColor:
                              selectedForm.design.colors.answerColor
                          }"
                        >
                          <icon name="qr" />
                        </div>
                        <div
                          class="share-btn"
                          v-if="selectedForm.endPage.shareLinks.fb"
                          :style="{
                            backgroundColor:
                              selectedForm.design.colors.answerColor
                          }"
                        >
                          <icon name="fb" />
                        </div>
                        <div
                          class="share-btn"
                          v-if="selectedForm.endPage.shareLinks.wa"
                          :style="{
                            backgroundColor:
                              selectedForm.design.colors.answerColor
                          }"
                        >
                          <icon name="wa" />
                        </div>
                        <div
                          class="share-btn"
                          v-if="selectedForm.endPage.shareLinks.es"
                          :style="{
                            backgroundColor:
                              selectedForm.design.colors.answerColor
                          }"
                        >
                          <icon name="es" />
                        </div>
                      </div>
                      <a
                        class="return"
                        href="http://www.survein.com"
                        :style="{
                          color: selectedForm.design.colors.descriptionColor
                        }"
                        >{{ $t("returnToSurvein") }}</a
                      >
                    </div>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </template>
        <template v-if="activeTab === 'logic'"> </template>
      </div>
    </div>
    <b-modal
      :id="insertOrSiblingModal.id"
      centered
      @hide="insertOrSiblingModal.hideHandler"
    >
      <p>{{ $t("insertOrSiblingModal.content") }}</p>
      <b-form-group>
        <b-form-radio
          v-for="option of insertOrSiblingModal.options"
          :key="option.value"
          v-model="insertOrSiblingModal.selected"
          :value="option.value"
        >
          {{ $t(`insertOrSiblingModal.labels.${option.label}`) }}
        </b-form-radio>
      </b-form-group>
    </b-modal>

    <transition name="fade">
      <div v-if="showFormLimitWarning" class="alert-limit">
        <div class="d-flex flex-row justify-content-between">
          <span class="font-weight-bold">{{ $t("alert.planLimit") }}</span>
          <div class="cursor-pointer" @click="closeAlert()">
            <icon name="close"></icon>
          </div>
        </div>
        <div class="mt-2">
          <span>
            {{ $t("alert.formReachedLimit") }}
          </span>
          <b-progress
            v-if="selectedTeam.license === 'FREE'"
            style="margin-top: 12px"
            height="4px"
            :value="formFields.length"
            max="10"
            :variant="formFields.length > 10 ? 'danger' : 'primary'"
          ></b-progress>
          <div
            v-if="selectedTeam.license === 'FREE'"
            class="d-flex justify-content-between"
            style="width:216px"
          >
            <span class="text-description mt-1">{{
              $t("alert.questions")
            }}</span>
            <span class="text-description mt-1"
              >{{ formFields.length }}/10</span
            >
          </div>
          <b-progress
            style="margin-top: 12px"
            height="4px"
            :value="logicJumpsCount"
            :max="logicJumpsLimit"
            :variant="logicJumpsCount > logicJumpsLimit ? 'danger' : 'primary'"
          ></b-progress>
          <div class="d-flex justify-content-between" style="width:216px">
            <span class="text-description mt-1">{{
              $t("alert.logicJump")
            }}</span>
            <span class="text-description mt-1"
              >{{ logicJumpsCount }} / {{ logicJumpsLimit }}</span
            >
          </div>
          <b-button
            v-b-modal.subscription-upgrade
            class="mt-2"
            size="sm"
            style="width: 216px; margin-top: 12px"
            variant="primary"
            >{{ $t("alert.upgradeNow") }}</b-button
          >
        </div>
      </div>
    </transition>
  </div>
</template>

<i18n>
{
  "en": {
    "insertOrSiblingModal": {
      "content": "Do you want to insert or add as a sibling?",
      "labels": {
        "Insert": "Insert",
        "Add as sibling": "Add as sibling"
      }  
    },
    "previewBlock": {
      "description": "Drag blocks to the diagram to build your form"
    },
    "alert": {
      "planLimit": "Plan Limits Exceeded",
      "formReachedLimit": "This form has reached its limits. Remove some of below features or upgrade for more features.",
      "questions": "Questions",
      "logicJump": "Logic Jump",
      "upgradeNow": "Upgrade now"
    },
    "returnToSurvein": "Return to Survein Website",
    "thankYouUpdated": "Thank you screen updated",
    "formFieldSaved": "Form field saved",
    "colorUpdated": "Color updated",
    "backgroundUpdated": "Background updated",
    "formFieldDeleted": "Form field deleted"
  },
  "id": {
    "insertOrSiblingModal": {
      "content": "Anda ingin menyisipkan atau menambahkan?",
      "labels": {
        "Insert": "Menyisipkan",
        "Add as sibling": "Menambahkan sebagai saudara kandung"
      }
    },
    "previewBlock": {
      "description": "Seret blok ke diagram untuk membuat formulir Anda"
    },
    "alert": {
      "planLimit": "Batas Paket Terlampaui",
      "formReachedLimit": "Formulir ini telah mencapai batasnya. Hapus beberapa fitur di bawah atau tingkatkan untuk lebih banyak fitur.",
      "questions": "Pertanyaan",
      "logicJump": "Lompatan Logika",
      "upgradeNow": "Tingkatkan"
    },
     "returnToSurvein": "Kembali ke Website Survein",
     "thankYouUpdated": "Layar Terima Kasih telah diperbarui",
     "formFieldSaved": "Bidang formulir disimpan",
     "backgroundUpdated": "Background diperbarui",
     "formFieldDeleted": "Bidang formulir dihapus"
  }
}
</i18n>

<script>
import { mapActions, mapGetters } from "vuex";
import FlowyBlockNode from "@/components/common/FlowyBlockNode";
import FlowyBlockPreview from "@/components/common/FlowyBlockPreview";
import FormBuilderFormField from "@/components/FormBuilder/FormBuilderFormField";
import createFormField from "@/helpers/createFormField";
import formFieldsToNodes from "@/helpers/formFieldsToNodes";
import createFormFieldBlocks from "@/helpers/createFormFieldBlocks";
import { EventBus } from "@/helpers/EventBus";
import { startNodeId, startNodeParentId } from "@/constants/reservedNodeIds";
import FormStyleEditor from "../components/Form/FormStyleEditor";
import FormSidebarMenu from "../components/Form/FormSidebarMenu";
import FormResponseSetting from "../components/Form/FormResponseSetting";
import FormBuilderNewQuestionQuiz from "../components/FormBuilder/FormBuilderNewQuestionQuiz";
import formFieldTypes from "@/constants/formFieldTypes";
import { omit, cloneDeep } from "lodash";
import createStartNode from "@/helpers/createStartNode";

export default {
  components: {
    FlowyBlockNode,
    FlowyBlockPreview,
    FormBuilderFormField,
    FormStyleEditor,
    FormSidebarMenu,
    FormResponseSetting,
    FormBuilderNewQuestionQuiz
  },
  props: {
    teamId: {
      type: String,
      required: true
    },
    workspaceId: {
      type: String,
      required: true
    },
    formId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showFlowDrag: true,
      showFormStyleEditor: false,
      showFormResponseSetting: false,
      showFormBuilderFormField: false,
      showFormBuilderNewQuestion: false,
      newDraggingBlock: null,
      dragging: false,
      isSaving: false,
      insertOrSiblingModal: {
        id: "insert-or-sibling-modal",
        selected: 0,
        options: [
          {
            value: 0,
            label: "Insert"
          },
          {
            value: 1,
            label: "Add as sibling"
          }
        ],
        hideHandler: () => {}
      },
      formBuilderFormFieldData: {
        isNew: false,
        isInsert: true,
        parentId: null,
        isEditing: false,
        value: {}
      },
      formBuilderNewQuestionParentId: null,
      formFieldBlocks: createFormFieldBlocks(true),
      formFieldTypes,
      selectedForm: {},
      activeTab: "questions",
      showStartDropBox: false,
      options: {
        draggable: ".item",
        dropzone: ".dropzone"
      }
    };
  },
  computed: {
    ...mapGetters("form", [
      "form",
      "forms",
      "versions",
      "selectedVersion",
      "showFormLimitWarning"
    ]),
    ...mapGetters("team", ["selectedTeam"]),

    formFields() {
      return this.selectedForm && this.selectedForm.fields
        ? this.selectedForm.fields
        : [];
    },
    nodes() {
      return [createStartNode(), ...formFieldsToNodes(this.formFields)];
    },
    showShareLinks() {
      const form = this.form;
      if (form) {
        return (
          form.endPage.shareLinks.es ||
          form.endPage.shareLinks.fb ||
          form.endPage.shareLinks.qr ||
          form.endPage.shareLinks.wa
        );
      } else {
        return false;
      }
    },
    logicJumpsCount() {
      let count = 0;
      for (const field of this.formFields) {
        if (field.logics && field.logics.length > 0) {
          count++;
        }
      }
      return count;
    },
    logicJumpsLimit() {
      let limit = 0;
      if (this.selectedTeam) {
        if (this.selectedTeam.license === "FREE") {
          limit = 1;
        }
        if (this.selectedTeam.license === "ESSENTIALS") {
          limit = 3;
        }
        if (this.selectedTeam.license === "PROFESSIONAL") {
          limit = 5;
        }
        if (this.selectedTeam.license === "PREMIUM") {
          limit = 10;
        }
      }
      return limit;
    }
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      async handler() {
        this.selectedForm = cloneDeep(this.form);
      }
    }
  },
  async mounted() {
    this.setSelectedForm({ formId: this.formId, version: "DRAFT" });
    EventBus.$on("node-click", this.onNodeClick);
  },
  beforeDestroy() {
    EventBus.$off("node-click", this.onNodeClick);
  },
  methods: {
    ...mapActions("form", [
      "saveFormField",
      "saveFormFields",
      "rearrangeFormField",
      "removeFormField",
      "updateFormDesign",
      "updateFormBackground",
      "updateEndPage",
      "getForm",
      "getFormResponse",
      "setIsFormChanged",
      "setShowFormLimitWarning",
      "setSelectedForm"
    ]),
    ...mapActions("team", ["getTeam"]),

    onDragStartNewBlock(event) {
      console.log("onDragStartNewBlock", event);
      const { props } = event;
      this.newDraggingBlock = props;

      if (this.nodes.length <= 1) {
        this.showStartDropBox = true;
      }
    },
    onDragStopNewBlock(event) {
      console.log("onDragStopNewBlock", event);
      this.newDraggingBlock = null;
    },
    // REQUIRED
    beforeMove({ to, from }) {
      // called before moving node (during drag and after drag)
      // indicator will turn red when we return false
      // from is null when we're not dragging from the current node tree
      console.log("beforeMove", to, from);

      // we cannot drag upper parent nodes in this demo
      if (from && from.parentId === startNodeParentId) {
        return false;
      }

      // we're adding a new node (not moving an existing one)
      // if (from === null) {
      //   // we've passed this attribute to the demo-node
      //   if (this.newDraggingBlock["custom-attribute"] === false) {
      //     return false;
      //   }
      // }

      return true;
    },
    // REQUIRED
    beforeAdd({ to, from }) {
      // called before moving node (during drag and after drag)
      // indicator will turn red when we return false
      // from is null when we're not dragging from the current node tree
      console.log("beforeAdd", to, from);

      // we've passed this attribute to the demo-node
      // if (this.newDraggingBlock["custom-attribute"] === false) {
      //   return false;
      // }

      return true;
    },
    async remove(event) {
      console.log("remove", event);

      // node we're dragging to
      const { node } = event;

      // if it has multiple children and it is first node, do nothing
      // TODO: need warning message to indicate users are not allow
      // remove first node if it has multiple children
      const directChildNodes = this.nodes.filter(n => n.parentId === node.id);
      if (node.parentId === startNodeId && directChildNodes.length > 1) {
        return;
      }

      this.isSaving = true;
      await this.removeFormField({
        removedId: node.id
      });
      this.$toast.success(this.$t("formFieldDeleted"));
      if (
        this.formFields.length <= 10 &&
        this.selectedTeam.license === "FREE"
      ) {
        this.setShowFormLimitWarning(false);
      }
      this.isSaving = false;
      this.setIsFormChanged(true);
      this.openFlowDrag();
    },
    async move(event) {
      console.log("move", event);

      // node we're dragging to and node we've just dragged
      const { dragged, to } = event;

      // if position not changed at all, do nothing
      if (dragged.parentId === to.id) {
        return;
      }

      // if it has multiple children and it is first node, do nothing
      // TODO: need warning message to indicate users are not allow
      // re-arange first node if it has multiple children
      const directChildNodes = this.nodes.filter(
        n => n.parentId === dragged.id
      );
      if (dragged.parentId === startNodeId && directChildNodes.length > 1) {
        return;
      }

      // const parentId = to.id;
      // const hasSibling = this.nodes.some(n => n.parentId === parentId);

      let isInsert = true;
      // if (parentId !== startNodeId && hasSibling) {
      //   isInsert = await this.openInsertOrSiblingModal();
      //   // indicate that modal has been cancelled
      //   if (isInsert == null) {
      //     return;
      //   }
      // }

      this.isSaving = true;
      await this.rearrangeFormField({
        isInsert,
        draggedId: dragged.data.id,
        toId: to.id
      });

      this.$nextTick(() => {
        this.$nextTick(async () => {
          const newFields = this.rearrangeQuestionOrder();

          await this.saveFormFields({
            fields: newFields,
            vm: this
          });
        });
      });
      this.isSaving = false;
    },
    async add(event) {
      console.log("add", event);
      const parentId = event.node.parentId;
      // const hasSibling = this.nodes.some(n => n.parentId === parentId);

      // if (parentId !== startNodeId && hasSibling) {
      //   isInsert = await this.openInsertOrSiblingModal();
      //   // indicate that modal has been cancelled
      //   if (isInsert == null) {
      //     return;
      //   }
      // }

      this.saveFormField({
        isNew: true,
        isInsert: true,
        parentId,
        value: createFormField(event.node.data.type),
        isEditing: false
      });

      this.$nextTick(() => {
        this.$nextTick(async () => {
          const newFields = this.rearrangeQuestionOrder();

          await this.saveFormFields({
            fields: newFields,
            vm: this
          });
        });
      });
    },
    rearrangeQuestionOrder() {
      let fieldParentId = "1";
      let newFields = [];
      const newNodes = cloneDeep(this.nodes);

      newNodes.splice(
        newNodes.findIndex(item => item.parentId === -1),
        1
      );

      do {
        const field = newNodes.find(item => item.parentId === fieldParentId);
        newFields.push(field.data);
        fieldParentId = field.id;
        newNodes.splice(
          newNodes.findIndex(item => item.id === field.id),
          1
        );
      } while (newNodes.length > 0);

      return newFields;
    },
    onDragStart(event) {
      console.log("onDragStart", event);
      this.dragging = true;
    },
    openQuestionField(event) {
      console.log("openQuestion", event);
      this.formBuilderNewQuestionParentId = event;
      this.showFormBuilderNewQuestion = true;
      this.showFormStyleEditor = false;
      this.showFlowDrag = false;
      this.showFormResponseSetting = false;
      this.showFormBuilderFormField = false;
    },

    // return true if node is to be insert
    // return false if node is to be add as sibling
    // return null if modal has been closed without decision
    openInsertOrSiblingModal() {
      return new Promise(resolve => {
        this.insertOrSiblingModal.selected = 0;
        this.insertOrSiblingModal.hideHandler = bvModalEvent => {
          const hasCancelled = bvModalEvent.trigger !== "ok";
          const isInsert = hasCancelled
            ? null
            : this.insertOrSiblingModal.selected === 0;
          resolve(isInsert);
        };
        this.$bvModal.show(this.insertOrSiblingModal.id);
      });
    },
    openFormBuilderFormField(
      formField,
      parentId,
      isInsert = true,
      isNew = false,
      isEditing = false
    ) {
      this.formBuilderFormFieldData.isNew = isNew;
      this.formBuilderFormFieldData.isInsert = isInsert;
      this.formBuilderFormFieldData.value = formField;
      this.formBuilderFormFieldData.parentId = parentId;
      this.formBuilderFormFieldData.isEditing = isEditing;
      this.showFormStyleEditor = false;
      this.showFlowDrag = false;
      this.showFormResponseSetting = false;
      this.showFormBuilderNewQuestion = false;
      this.showFormBuilderFormField = true;
    },
    async onSaveFormField(formField, auto) {
      if (formField.type === formFieldTypes.END_PAGE) {
        this.isSaving = true;
        await this.updateEndPage({
          teamId: this.teamId,
          workspaceId: this.workspaceId,
          formId: this.formId,
          endPage: omit(formField, "type")
        });
        this.isSaving = false;
        if (!auto) {
          this.openFlowDrag();
        }
        this.$toast.success(this.$t("thankYouUpdated"));
        this.setIsFormChanged(true);
        return;
      }
      this.isSaving = true;
      this.setIsFormChanged(true);
      this.formBuilderFormFieldData.value = formField;
      this.saveFormField(this.formBuilderFormFieldData);
      this.$nextTick(() => {
        this.$nextTick(async () => {
          const newFields = this.rearrangeQuestionOrder();

          await this.saveFormFields({
            fields: newFields,
            vm: this
          });
        });
      });
      this.isSaving = false;

      if (!auto) {
        this.openFlowDrag();
      }
    },
    onNodeClick(fieldId) {
      const formField = this.formFields.find(field => field.id === fieldId);

      this.openFormBuilderFormField(formField, null, null, false, true);
    },
    openFormStyleEditor() {
      this.showFlowDrag = false;
      this.showFormBuilderFormField = false;
      this.showFormResponseSetting = false;
      this.showFormBuilderNewQuestion = false;
      this.showFormStyleEditor = true;
    },
    openFlowDrag() {
      this.showFormStyleEditor = false;
      this.showFormBuilderFormField = false;
      this.showFormResponseSetting = false;
      this.showFormBuilderNewQuestion = false;
      this.showFlowDrag = true;
    },
    openFormResponseSetting() {
      this.showFormStyleEditor = false;
      this.showFormBuilderFormField = false;
      this.showFlowDrag = false;
      this.showFormBuilderNewQuestion = false;
      this.showFormResponseSetting = true;
    },
    updateDesign(event) {
      this.$store.dispatch("form/updateFormDesign", {
        teamId: this.teamId,
        workspaceId: this.workspaceId,
        formId: this.formId,
        design: event,
        vm: this
      });
    },
    updateBackground(event) {
      this.$store.dispatch("form/updateFormBackground", {
        teamId: this.teamId,
        workspaceId: this.workspaceId,
        formId: this.formId,
        payload: event,
        vm: this
      });
    },
    closeAlert() {
      this.setShowFormLimitWarning(false);
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    async handleFirstDrop(data) {
      const type = data.oldComponent.$attrs.data.props.type;
      this.saveFormField({
        isNew: true,
        isInsert: true,
        parentId: 1,
        value: createFormField(type),
        isEditing: false
      });

      this.$nextTick(() => {
        this.$nextTick(async () => {
          const newFields = this.rearrangeQuestionOrder();

          await this.saveFormFields({
            fields: newFields,
            vm: this
          });
          this.showStartDropBox = false;
        });
      });
    },
    async onSaveNewQuestion(form) {
      this.isSaving = true;
      this.saveFormField({
        isNew: true,
        isInsert: true,
        parentId: this.formBuilderNewQuestionParentId,
        value: createFormField(form.type, form.title, form.description),
        isEditing: false
      });
      this.$nextTick(() => {
        this.$nextTick(async () => {
          const newFields = this.rearrangeQuestionOrder();

          await this.saveFormFields({
            fields: newFields,
            vm: this
          });
        });
      });
      this.isSaving = false;
      this.formBuilderNewQuestionParentId = null;
      this.showFormBuilderNewQuestion = false;
      this.showFlowDrag = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.bg-color {
  background-color: #f2f5f7;
}

.editor-wrapper {
  width: 380px;
  height: calc(100vh - 47px);
  overflow-x: hidden;
  overflow-y: auto;
}

.drag-wrapper {
  padding: 24px 24px 36px 24px;
  min-width: 80%;
  height: calc(100vh - 47px);
}

.flowy-block {
  margin-right: 0px !important;

  .flowy-drag-handle {
    margin-bottom: 8px !important;
  }
}

.fade-left-enter {
  transform: translateX(-20px);
  opacity: 0;
}

.fade-left-enter-active {
  transition: all 0.5s ease;
}

.fade-left-enter-to {
  transform: translateX(0px);
  opacity: 1;
}

.fade-right-enter {
  transform: translateX(20px);
  opacity: 0;
}

.fade-right-enter-active {
  transition: all 0.5s ease;
}

.fade-right-enter-to {
  transform: translateX(0px);
  opacity: 1;
}

.end-page-card {
  width: 64.5em;
  height: 22.5em;
  display: flex;
  background-color: white;
  border-radius: 8px;
  margin: auto;
  cursor: pointer;
}

.end-page-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overflow {
  overflow-y: auto;
}

.return {
  margin-top: 14px;
  text-decoration: none;
  display: inline-block;
}

.alert-limit {
  position: absolute;
  top: 16px;
  right: 24px;
  background: #ffffff;
  width: 248px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 4px 5px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 16px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.start-question {
  position: relative;
  top: 50%;
  transform: translateY(-75%);
}

.tab {
  box-shadow: inset 0px -1px 0px #d9dde1;
}

.tab-select {
  display: inline-block;
  padding: 10px 16px;
  cursor: pointer;

  &.active {
    border-bottom: 4px solid #166ed8;
  }
}

.start-dropbox {
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d0e2f7;
  border: 1px dashed #166ed8;
  box-sizing: border-box;
  border-radius: 8px;
}
</style>
