<template>
  <div class="p-4">
    <b-form-select v-model="form.type" :options="typeOptions"></b-form-select>
    <b-form class="mt-4" novalidate @submit.prevent="onSubmit">
      <div v-if="form.type === formFieldTypesQuiz.START_PAGE">
        <b-form-group :label="$t('title')">
          <b-form-textarea
            v-model.trim.lazy="form.title"
            placeholder="Ex: Welcome to our survey form..."
            rows="3"
            :disabled="isLoading"
          />
          <b-form-invalid-feedback>{{
            $t("titleErrMsg")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="$t('description')" class="mb-0">
          <vue-editor
            :disabled="isLoading"
            v-model="form.description"
            :editor-toolbar="customToolbar"
          ></vue-editor>
        </b-form-group>
      </div>
      <b-form-group v-else label="Question" class="mb-0">
        <vue-editor
          :disabled="isLoading"
          v-model.trim.lazy="form.description"
          :editor-toolbar="customToolbar"
        ></vue-editor>
      </b-form-group>
      <div>
        <small>max image size is 100 KB.</small>
      </div>
      <div class="mt-4 d-flex justify-content-center align-items-center">
        <b-button
          @click="cancel"
          class="mr-2"
          block
          :disabled="isLoading"
          variant="outline-primary"
        >
          <b-icon-arrow-clockwise v-if="isLoading" animation="spin" />
          <span v-else>{{ $t("cancel") }}</span>
        </b-button>
        <b-button
          class="m-0"
          block
          :disabled="isLoading"
          type="submit"
          variant="primary"
        >
          <b-icon-arrow-clockwise v-if="isLoading" animation="spin" />
          <span v-else>{{ $t("save") }}</span>
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<i18n src="@/locales/common/form-field-type-names.json"></i18n>

<i18n>
{
  "en": {
    "title": "Title",
    "titleErrMsg": "Title is required.",
    "description": "Description",
    "image": "Image",
    "chooseImg": "Choose Image",
    "button": "Button",
    "shareLinks": "Share Links",
    "required": "Required",
    "countryCode": "Country Code",
    "cancel" : "Cancel",
    "save": "Save",
    "edit": "Edit",
    "add": "Add",
    "removeLogic": "Remove logic",
    "saveChanges": "Save changes",
    "close": "Close",
    "logicJump": "Logic Jump"
  },
  "id": {
    "title": "Judul",
    "titleErrMsg": "Judul harus diisi.",
    "description": "Deskripsi",
    "image": "Gambar",
    "chooseImg": "Pilih Gambar",
    "button": "Button",
    "shareLinks": "Bagikan Tautan",
    "required": "Wajib",
    "countryCode": "Kode Negara",
    "cancel" : "Batal",
    "save": "Menyimpan",
    "edit": "Ubah",
    "add": "Tambah",
    "removeLogic": "Hapus logika",
    "saveChanges": "Simpan perubahan",
    "close": "Tutup",
    "logicJump": "Lompatan logika"
  }
}
</i18n>

<script>
import formFieldTypeNames from "@/constants/formFieldTypeNames";
import formFieldTypesQuiz from "@/constants/formFieldTypesQuiz";
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor
  },
  data() {
    return {
      form: {
        title: "Title",
        description: "Description",
        type: "START_PAGE"
      },
      typeOptions: [
        {
          value: "START_PAGE",
          text: "Welcome Screen"
        },
        {
          value: "MULTISELECT_QUIZ",
          text: "Multiple choice"
        },
        {
          value: "TRUE_FALSE",
          text: "True or False"
        }
      ],
      formFieldTypeNames,
      formFieldTypesQuiz,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        ["clean"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "link"]
      ]
    };
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    async onSubmit() {
      this.$emit("save-new-question", this.form);
    }
  },
  computed: {
    ...mapGetters({ formGetter: "form/form" }),

    hasFormFields() {
      return !!this.formGetter.fields && this.formGetter.fields.length > 0;
    },
    hasTitle() {
      return !!this.form.title;
    }
  }
};
</script>
