<template>
  <div>
    <b-form-group class="mt-3 mb-3" novalidate>
      <p>Choices</p>
      <div v-for="(option, index) in options" :key="index">
        <b-input-group class="mb-2">
          <b-form-input autofocus v-model="option.value"></b-form-input>
          <b-input-group-append is-text @click="deleteOption(index)">
            <b-icon icon="dash-circle-fill" />
          </b-input-group-append>
        </b-input-group>
      </div>
      <b-button class="p-0" variant="link" @click="addOption" size="sm">
        {{ $t("addChoice") }}
      </b-button>
    </b-form-group>
    <div class="mb-2">
      <span>{{ $t("required") }}</span>
      <b-form-checkbox
        :checked="required"
        name="required"
        class="mb-2"
        switch
        :class="'float-right'"
        @change="toggleRequired"
      >
      </b-form-checkbox>
    </div>
    <div
      class="mb-2 col-12 p-0 d-flex justify-content-between"
      v-if="type === formFieldTypes.MULTISELECT"
    >
      <span>{{ $t("allowMultipleAnswer") }}</span>
      <b-form-checkbox
        :checked="allowMultipleAnswer"
        name="allow-multiple"
        switch
        @change="toggleAllowMultiple"
        :class="'float-right'"
      >
      </b-form-checkbox>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "answer": "Answer",
    "addChoice": "Add choice",
    "required": "Required",
    "allowMultipleAnswer":"Allow Multiple Answer",
    "label" : "Label"
  },
  "id": {
    "answer": "Jawaban",
    "addChoice": "Tambah Pilihan",
    "required": "Wajib",
    "allowMultipleAnswer":"Ijinkan jawaban ganda",
    "label" : "Label"
  }
}
</i18n>

<script>
import formFieldTypes from "@/constants/formFieldTypes";

export default {
  data() {
    return {
      option: {
        label: "",
        value: ""
      },
      formFieldTypes
    };
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    required: {
      type: Boolean,
      required: true
    },
    allowMultipleAnswer: {
      type: Boolean,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    }
  },
  methods: {
    addOption() {
      this.$emit("update:options", [
        ...this.options,
        {
          label: this.option.label,
          value: this.option.value
        }
      ]);
      this.option.label = "";
      this.option.value = "";
    },
    deleteOption(index) {
      this.options.splice(index, 1);
      this.$emit("update:options", this.options);
    },
    toggleRequired($event) {
      this.$emit("update:required", $event);
    },
    toggleAllowMultiple($event) {
      this.$emit("update:allowMultipleAnswer", $event);
    }
  }
};
</script>
