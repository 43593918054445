<template>
  <div
    class="d-flex justify-content-center align-items-center"
    :class="isFormPublished ? '' : 'h-100'"
  >
    <div
      v-if="!isFormPublished"
      class="d-flex justify-content-center align-items-center flex-column m-auto"
    >
      <icon name="not-published" />
      <h4>{{ $t("formNotPublishedYet") }}</h4>
      <span>{{ $t("publishYourForm2") }}</span>
      <b-button class="mt-2" variant="primary" @click="goToWorkspace">{{
        $t("goToWorkspace")
      }}</b-button>
    </div>
    <div v-else>
      <b-container style="width: 800px" class="mt-5">
        <b-row no-gutters>
          <h5>{{ $t("share") }}</h5>
        </b-row>
        <b-row style="max-height: 206px" no-gutters>
          <b-col cols="7">
            <b-card class="h-100">
              <b-row no-gutters>
                <h5>{{ $t("links") }}</h5>
                <b-button
                  v-b-modal.custom-link
                  variant="link"
                  size="sm"
                  class="ml-auto"
                  >{{ $t("customizeLink") }}</b-button
                >
              </b-row>
              <b-row>
                <b-input-group class="col-md-12 mt-2">
                  <b-form-input disabled type="text" :value="url" />
                  <input
                    type="text"
                    :value="url"
                    ref="mylink"
                    class="copylink"
                  />
                  <b-input-group-append>
                    <b-button @click="copyToClipboard()" variant="primary">{{
                      $t("copy")
                    }}</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-row>
              <b-row no-gutters class="mt-3">
                <span>{{ $t("shareToCircleCommunity") }}</span>
              </b-row>
              <b-row no-gutters class="mt-2">
                <div class="share-links">
                  <div class="share-page-btn" @click="goToFbLink()">
                    <icon name="fb-share" />
                  </div>
                  <div class="share-page-btn" @click="goToWaLink()">
                    <icon name="wa-share" />
                  </div>
                  <div class="share-page-btn" @click="goToTwitterLink()">
                    <icon name="twitter" />
                  </div>
                  <div class="share-page-btn" @click="goToTelegramLink()">
                    <icon name="telegram" />
                  </div>
                </div>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="5" no-gutters>
            <b-card class="h-100 ml-3">
              <b-row no-gutters>
                <h5>{{ $t("qrCode") }}</h5>
              </b-row>
              <b-row no-gutters>
                <div class="card-qr">
                  <qr-code :size="88" :text="url"></qr-code>
                </div>
                <b-col no-gutters class="d-flex flex-column ml-2">
                  <span style="font-size: 14px;">{{
                    $t("downloadAndShareQR")
                  }}</span>
                  <b-button
                    v-b-modal.qrshare-modal
                    style="width: 100px"
                    class="mt-auto"
                    variant="primary"
                    >{{ $t("download") }}</b-button
                  >
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="12">
            <b-card style="height: 282px">
              <b-row no-gutters>
                <h5>{{ $t("embedForm") }}</h5>
              </b-row>
              <b-row no-gutters class="w-100">
                <b-col cols="4">
                  <div class="d-flex align-items-center">
                    <span style="width: 65px">{{ $t("width") }}</span>
                    <b-input-group style="width: 160px" class="ml-auto">
                      <b-form-input v-model="width"></b-form-input>

                      <template #append>
                        <b-form-select style="width: 80px" v-model="widthUnit">
                          <b-select-option value="%">%</b-select-option>
                          <b-select-option value="px">px</b-select-option>
                        </b-form-select>
                      </template>
                    </b-input-group>
                  </div>
                  <div class="d-flex align-items-center mt-2">
                    <span style="width: 65px">{{ $t("height") }}</span>
                    <b-input-group style="width: 160px" class="ml-auto">
                      <b-form-input v-model="height"></b-form-input>

                      <template #append>
                        <b-form-select style="width: 80px" v-model="heightUnit">
                          <b-select-option value="%">%</b-select-option>
                          <b-select-option value="px"
                            >px</b-select-option
                          ></b-form-select
                        >
                      </template>
                    </b-input-group>
                  </div>
                  <div class="d-flex flex-column mt-4">
                    <span>{{ $t("copyAndPaste") }}</span>
                    <b-button
                      class="mt-2"
                      variant="primary"
                      @click="copyEmbed"
                      >{{ $t("copyCode") }}</b-button
                    >
                  </div>
                </b-col>
                <b-col cols="8" class="pl-4">
                  <textarea
                    v-model="embedScript"
                    class="embedcopy"
                    ref="embed"
                    rows="6"
                    max-rows="6"
                  ></textarea>
                  <div class="embed-wrapper" id="embed">
                    {{ embedScript }}
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>

      <b-modal centered id="custom-link" no-stacking>
        <template #modal-title>
          <h5 class="mb-0 col-12">{{ $t("customizeLink") }}</h5>
        </template>
        <template #default>
          <div>
            <span>{{ $t("enterNewLink") }}</span>
            <b-input-group :prepend="formUrl" class="mt-3">
              <b-form-input
                v-model.trim="$v.customUrl.$model"
                placeholder="your-custom-url"
              ></b-form-input>
            </b-input-group>
          </div>
        </template>
        <template #modal-footer="{ cancel }">
          <b-button @click="cancel()" size="md" variant="light">
            {{ $t("cancel") }}
          </b-button>
          <b-button size="md" variant="primary" @click="saveCustomUrl">
            {{ $t("save") }}
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "cancel": "Cancel",
    "save": "Save",
    "share": "Share Your Form",
    "links": "Links",
    "customizeLink": "Customize link preview",
    "shareToCircleCommunity": "or share to circle community",
    "copy": "Copy",
    "qrCode": "QR Code",
    "downloadAndShareQR": "Download and share the form through QR code.",
    "download": "Download",
    "embedForm": "Embed Form",
    "width": "Width",
    "height": "Height",
    "copyAndPaste": "Copy and paste the code into your website or blog.",
    "copyCode": "Copy the code",
    "enterNewLink": "Please enter a new link",
    "linkCopied": "Link is copied",
    "embedCopied": "Embed code is copied",
    "linkChanged": "Link is changed",
    "linkError": "Link already used",
    "formNotPublishedYet": "Sorry, it seems your form is not published yet",
    "publishYourForm2": "Please publish your form first",
    "goToWorkspace": "Go To Workspace"
  },
  "id": {
     "cancel": "Batal",
     "save": "Simpan",
     "share": "Bagikan Formulir Anda",
    "links": "Tautan",
    "customizeLink": "Kustomisasi tautan",
    "shareToCircleCommunity": "atau bagikan ke komunitas",
    "copy": "Salin",
    "qrCode": "Kode QR",
    "downloadAndShareQR": "Unduh dan bagikan formulir melalui kode QR.",
    "download": "Unduh",
    "embedForm": "Sematkan Formulir",
    "width": "Lebar",
    "height": "Tinggi",
    "copyAndPaste": "Salin dan tempel kode tersebut ke situs web atau blog Anda.",
    "copyCode": "Salin kode",
    "enterNewLink": "Harap masukkan tautan baru",
    "linkCopied": "Tautan disalin",
    "embedCopied": "Kode sematan disalin",
    "linkChanged": "Tautan diubah",
    "linkError": "Link already used",
    "formNotPublishedYet": "Maaf, sepertinya formulir Anda belum diterbitkan",
    "publishYourForm2": "Harap terbitkan formulir anda terlebih dahulu",
    "goToWorkspace": "Buka Ruang Kerja"
  }
}
</i18n>

<script>
import { mapActions, mapGetters } from "vuex";
import { cloneDeep } from "lodash";
import VueQRCodeComponent from "vue-qrcode-component";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    "qr-code": VueQRCodeComponent
  },
  props: {
    teamId: {
      type: String,
      required: true
    },
    workspaceId: {
      type: String,
      required: true
    },
    formId: {
      type: String,
      required: true
    }
  },
  validations: {
    customUrl: {
      required
    }
  },
  data() {
    return {
      selectedForm: {},
      isLoading: false,
      width: "500",
      widthUnit: "px",
      height: "300",
      heightUnit: "px",
      customUrl: ""
    };
  },
  computed: {
    ...mapGetters("form", [
      "form",
      "forms",
      "selectedVersion",
      "response",
      "findForm"
    ]),

    formUrl() {
      return `${process.env.VUE_APP_FORM_ENDPOINT}/`;
    },

    url() {
      const publishedForm = this.findForm(this.form.id, "PUBLISHED");
      return publishedForm.customUrl
        ? `${process.env.VUE_APP_FORM_ENDPOINT}/${publishedForm.customUrl}`
        : `${process.env.VUE_APP_FORM_ENDPOINT}/${this.formId}`;
    },

    embedScript() {
      return `<div style="width: ${this.width}${this.widthUnit} height: ${this.height}${this.heightUnit}><iframe src="${this.url}"></iframe></div>`;
    },

    isFormPublished() {
      return !!this.findForm(this.formId, "PUBLISHED");
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.getForm({
      teamId: this.teamId,
      workspaceId: this.workspaceId,
      formId: this.formId
    });
    if (this.form.customUrl) {
      this.customUrl = this.form.customUrl;
    }
    this.isLoading = false;
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler() {
        this.selectedForm = cloneDeep(this.form);
      }
    }
  },
  methods: {
    ...mapActions("form", ["setSelectedVersion", "getForm", "updateCustomUrl"]),

    goToWorkspace() {
      this.$router.push({ name: "Workspace" });
    },

    goToFbLink() {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${this.url}`,
        "_blank"
      );
    },

    goToWaLink() {
      const LinkTextToShare =
        "https://api.whatsapp.com/send?text=" + encodeURIComponent(this.url);

      window.open(LinkTextToShare, "_blank");
    },

    goToTwitterLink() {
      window.open(
        "https://twitter.com/share?url=" + encodeURIComponent(this.url)
      );
    },

    goToTelegramLink() {
      window.open(`https://telegram.me/share/url?url=${this.url}`);
    },

    copyToClipboard() {
      let url = this.$refs.mylink;
      url.select();
      document.execCommand("copy");
      this.$toast.success(this.$t("linkCopied"));
    },

    copyEmbed() {
      let embedString = this.$refs.embed;
      embedString.select();
      document.execCommand("copy");
      this.$toast.success(this.$t("embedCopied"));
    },

    async saveCustomUrl() {
      if (this.$v.$invalid) {
        this.$toast.warning("Link is required");
        return;
      } else if (this.customUrl === this.form.customUrl) {
        this.$bvModal.hide("custom-link");
      } else {
        this.customUrl = this.customUrl.replace(/\s+/g, "-");

        await this.updateCustomUrl({
          teamId: this.teamId,
          formId: this.formId,
          customUrl: this.customUrl,
          vm: this
        });

        this.$bvModal.hide("custom-link");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card-qr {
  background: #edeff2;
  border-radius: 8px;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.embed-wrapper {
  background: #1f2432;
  color: white;
  border-radius: 8px;
  width: 100%;
  height: 200px;
  padding: 24px;
}
.copylink {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.embedcopy {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
</style>
