<template>
  <div>
    <div
      v-if="!responses"
      class="d-flex justify-content-center align-items-center flex-column"
      style="margin-top: 130px"
    >
      <icon name="no-response" />
      <h4>{{ $t("noResponseYet") }}</h4>
      <span style="width: 350px; text-align: center">{{
        $t("publishYourForm")
      }}</span>
    </div>

    <b-row v-if="responses">
      <b-col>
        <div
          style="maxWidth:754px"
          class="ml-auto mr-auto mb-3 d-flex flex-row justify-content-between"
        >
          <div class="d-flex flex-row align-items-center">
            <span>{{ $t("show") }}</span>
            <b-form-select
              :options="showParticipant"
              v-model="perPage"
              class="participant-select"
            ></b-form-select>
            <span>{{ $t("participantPage") }}</span>
          </div>
          <div class="d-flex flex-row align-items-center">
            <span class="mr-2">{{ $t("sortBy") }}</span>
            <div>
              <b-form-select
                v-model="sortSelected"
                :options="sortScore"
              ></b-form-select>
            </div>
          </div>
        </div>
        <b-card
          style="height: 52px; maxWidth:754px"
          class="ml-auto mr-auto mb-2 card-border"
          v-for="participant in paginate"
          :key="participant.id"
        >
          <div class="d-flex flex-row align-items-center">
            <div
              class="rank-wrapper"
              :class="{ 'top-three': isTopThree(participant.ranking) }"
            >
              <span class="rank">Rank</span>
              <span class="rank-number">{{ participant.ranking }}</span>
            </div>
            <div class="participant-wrapper">
              <b-avatar variant="warning" text="A" size="30"></b-avatar>
              <div class="d-flex flex-column ml-4">
                <span class="participant-name">{{
                  participant.data.username
                    ? participant.data.username
                    : "John Doe"
                }}</span>
                <span class="participant-description"
                  >({{ $t("participantNumber") }}
                  {{ participant.respondentNo }})</span
                >
              </div>
            </div>
            <div class="quiz-score-wrapper">
              <div
                class="answer-correct"
                :class="
                  participant.data.totalWrongAnswer === 0
                    ? 'add-border-radius'
                    : ''
                "
                :style="
                  'width: ' + correctAnswerPercentage(participant.data) + '%;'
                "
              >
                <span class="ml-2">{{
                  participant.data.totalCorrectAnswer
                }}</span>
              </div>
              <div
                class="answer-false"
                :class="
                  participant.data.totalCorrectAnswer === 0
                    ? 'add-border-radius'
                    : ''
                "
                :style="
                  'width: ' + wrongAnswerPercentage(participant.data) + '%;'
                "
              >
                <span class="mr-2">{{
                  participant.data.totalWrongAnswer
                }}</span>
              </div>
            </div>
            <div class="quiz-point-wrapper">
              <span class="mr-2">{{ participant.data.totalScore }}</span>
              <icon name="trophy" />
            </div>
          </div>
        </b-card>

        <div
          style="min-height: 56px; maxWidth:754px"
          class="d-flex justify-content-center align-items-center flex-row ml-auto mr-auto mb-2"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="allResponses.length"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<i18n>
{
  "en": {
    "noResponseYet": "No responses yet",
    "publishYourForm": "Publish the form & share to your circle, and you will see their responses here.",
    "respondent": "Respondent no.",
    "from": "from",
    "participantPage": "participant on page",
    "show": "Show",
    "sortBy": "Sort by",
    "participantNumber": "Participant no.",
    "highestScore": "Highest score",
    "lowestScore": "Lowest score"
  },
  "id": {
     "noResponseYet": "Belum mendapatkan tanggapan",
     "publishYourForm": "Publikasikan formulir & bagikan ke sekitar Anda, dan Anda akan melihat tanggapan mereka di sini.",
     "respondent": "Responden no.",
     "from": "dari",
     "participantPage": "peserta di halaman",
     "show": "Tampilkan",
     "sortBy": "Sortir berdasarkan",
     "participantNumber": "Peserta no.",
     "highestScore": "Skor tertinggi",
     "lowestScore": "Skor terendah"

  }
}
</i18n>

<script>
import { mapGetters, mapActions } from "vuex";
import { cloneDeep } from "lodash";

export default {
  props: {
    teamId: {
      type: String,
      required: true
    },
    workspaceId: {
      type: String,
      required: true
    },
    formId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedIndex: 1,
      responses: {},
      allResponses: [],
      showParticipant: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" }
      ],
      sortScore: [
        { value: "highestScore", text: this.$t("highestScore") },
        { value: "lowestScore", text: this.$t("lowestScore") }
      ],
      sortSelected: "highestScore",
      perPage: 10,
      currentPage: 1
    };
  },
  computed: {
    ...mapGetters("form", [
      "form",
      "forms",
      "selectedVersion",
      "response",
      "responseByIndex"
    ]),
    allResponsesWithRanking() {
      let all = cloneDeep(this.allResponses);
      return all
        .sort((a, b) => a.data.createdAt - b.data.createdAt)
        .sort((a, b) => b.data.totalScore - a.data.totalScore)
        .map((r, index) => ({ ...r, ranking: index + 1 }));
    },
    sortResponse() {
      let all = cloneDeep(this.allResponsesWithRanking);
      if (this.sortSelected === "highestScore") {
        return all
          .sort((a, b) => {
            return b.data.totalScore - a.data.totalScore;
          })
          .sort((a, b) => {
            return a.ranking - b.ranking;
          });
      } else {
        return all
          .sort((a, b) => {
            return a.data.totalScore - b.data.totalScore;
          })
          .sort((a, b) => {
            return b.ranking - a.ranking;
          });
      }
    },
    paginate() {
      return this.sortResponse.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    }
  },
  async mounted() {
    this.allResponses = cloneDeep(this.response);
  },
  watch: {
    selectedIndex: {
      immediate: true,
      handler(i) {
        this.responses = cloneDeep(this.responseByIndex(i - 1));
        if (i == 0) {
          this.responses = cloneDeep(this.responseByIndex(0));
        }
        if (i > this.response.length) {
          this.responses = cloneDeep(
            this.responseByIndex(this.response.length - 1)
          );
        }
      }
    }
  },
  methods: {
    ...mapActions("form", ["getFormResponse", "setSelectedVersion", "getForm"]),

    nextIndex() {
      if (this.selectedIndex === this.allResponses.length) return;
      this.selectedIndex = this.selectedIndex + 1;
    },
    previousIndex() {
      if (this.selectedIndex === 1) return;
      this.selectedIndex = this.selectedIndex - 1;
    },
    correctAnswerPercentage(value) {
      return Math.floor(
        (value.totalCorrectAnswer /
          (value.totalCorrectAnswer + value.totalWrongAnswer)) *
          100
      );
    },
    wrongAnswerPercentage(value) {
      return Math.floor(
        (value.totalWrongAnswer /
          (value.totalCorrectAnswer + value.totalWrongAnswer)) *
          100
      );
    },
    isTopThree(ranking) {
      return ranking > 0 && ranking < 4;
    }
  }
};
</script>

<style lang="scss" scoped>
.w-536 {
  max-width: 536px;
  margin: auto;
}

.card-body {
  padding: 0px !important;
}

.rank-wrapper {
  width: 40px;
  height: 100%;
  background: #f4f5f7;
  border: 1px solid #d9dde1;
  padding: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  .rank {
    font-size: 10px;
  }

  .rank-number {
    font-size: 16px;
  }

  &.top-three {
    background: #166ed8 !important;
    color: white !important;
  }
}

.participant-wrapper {
  display: flex;
  flex-direction: row;
  margin-left: 24px;
  width: 40%;
  align-items: center;

  .participant-name {
    font-weight: 700;
  }

  .participant-description {
    font-size: 10px;
    color: #5e6674;
  }
}

.quiz-point-wrapper {
  margin-left: auto;
  margin-right: 16px;
  display: flex;
  flex-direction: row;
}

.quiz-score-wrapper {
  width: 40%;
  height: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;

  .answer-correct {
    background-color: #3fcb9c;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    color: white;

    &.add-border-radius {
      border-radius: 8px;
    }
  }
  .divide {
    width: 1%;
    background-color: white;
  }
  .answer-false {
    background-color: #f06565;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    color: white;
    text-align: end;

    &.add-border-radius {
      border-radius: 8px;
    }
  }
}

.participant-select {
  width: 58px;
  height: 34px;
  margin-left: 8px;
  margin-right: 8px;
}
</style>
