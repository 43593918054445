import { render, staticRenderFns } from "./FormBuilder.vue?vue&type=template&id=0b309476&scoped=true&"
import script from "./FormBuilder.vue?vue&type=script&lang=js&"
export * from "./FormBuilder.vue?vue&type=script&lang=js&"
import style0 from "./FormBuilder.vue?vue&type=style&index=0&id=0b309476&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b309476",
  null
  
)

/* custom blocks */
import block0 from "./FormBuilder.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports