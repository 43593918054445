<template>
  <b-col v-if="answers.length > 0 && answers[0].answer !== ''">
    <div
      class="long-text d-flex align-items-center p-2 mb-2"
      v-for="(answer, index) of answers"
      :key="index"
    >
      <span class="ml-2">{{ answer.answer }}</span>
    </div>
  </b-col>
  <b-col v-else>
    <div class="no-answer">No answer</div>
  </b-col>
</template>

<script>
export default {
  props: {
    answers: {
      type: Array
    }
  }
};
</script>

<style lang="scss" scoped>
.long-text {
  background: #edeff2;
  border-radius: 4px;
}
</style>
