<template>
  <section>
    <div class="p-4">
      <h5 class="mb-0">{{ $t("settings") }}</h5>
    </div>
    <div
      @click="showCloseFormModal()"
      class="py-2 px-4 d-flex justify-content-between align-items-center"
      :style="
        isAcceptingResponse ? 'cursor: pointer' : 'background-color: #E6E9ED'
      "
    >
      <span>{{ $t("manageRespondentAccess") }}</span>
      <b-icon icon="chevron-right"></b-icon>
    </div>

    <CloseFormModal
      :teamId="teamId"
      :workspaceId="workspaceId"
      :formId="formId"
      :modalId="'set-close-response'"
      ref="modalComponent"
    />
  </section>
</template>

<i18n>
{
  "en": {
    "settings": "Settings",
    "manageRespondentAccess": "Manage respondent access"
  },
  "id": {
    "settings": "Pengaturan",
    "manageRespondentAccess": "Kelola akses responden"
  }
}
</i18n>

<script>
import { mapGetters } from "vuex";
import CloseFormModal from "../common/CloseFormModal";

export default {
  components: {
    CloseFormModal
  },
  props: {
    teamId: {
      type: String,
      required: true
    },
    workspaceId: {
      type: String,
      required: true
    },
    formId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters("form", ["versions", "form", "findForm"]),

    isAcceptingResponse() {
      return (
        this.findForm(this.formId, "PUBLISHED") &&
        this.findForm(this.formId, "PUBLISHED").acceptingResponse
      );
    }
  },
  methods: {
    showCloseFormModal() {
      if (this.isAcceptingResponse) {
        this.$refs.modalComponent.showCloseModal();
      }
    }
  }
};
</script>
