<template>
  <div>
    <b-col
      v-for="(value, index) in values"
      :key="index"
      md="auto"
      class="d-flex flex-row p-0 align-items-center mb-2"
    >
      <div class="bar-percent">
        <span>{{ answerPercentage(value) }}%</span>
      </div>
      <div class="bar-chart-wrapper d-flex align-items-center">
        <span class="value">{{ value.label }}</span>
        <div
          class="bar-chart-progress"
          :style="'width: ' + answerPercentage(value) + '%'"
        ></div>
      </div>
      <div class="bar-result">
        <span>{{ answerCount(value) }}</span>
      </div>
    </b-col>
  </div>
</template>

<script>
export default {
  props: {
    answers: {
      type: Array
    },
    options: {
      type: Array
    }
  },
  data() {
    return {
      values: [
        { label: "True", value: "a" },
        { label: "False", value: "b" }
      ]
    };
  },
  methods: {
    answerCount(value) {
      let count = 0;
      for (const answer of this.answers) {
        if (answer.answer === value.value) {
          count++;
        }
      }
      return count;
    },
    answerPercentage(value) {
      let count = 0;
      if (this.answers.length) {
        for (const answer of this.answers) {
          if (answer.answer === value.value) {
            count++;
          }
        }
        return Math.floor((count / this.answers.length) * 100);
      } else {
        return 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bar-percent {
  max-height: 36px;
  width: 60px;
  text-align: center;
  padding: 6px;
}
.bar-chart-wrapper {
  background: #e8f1fb;
  height: 36px;
  width: 320px;
  position: relative;
}
.bar-chart-progress {
  background: #b9d4f3;
  height: 36px;
  text-align: center;
  padding: 6px;
  overflow: hidden;
}
.bar-result {
  width: 32px;
  height: 26px;
  border-radius: 4px;
  background: #e8f1fb;
  color: #3881d7;
  text-align: center;
  padding: 3px;
  font-size: 14px;
  margin-left: 8px;
}
.value {
  position: absolute;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
}
.step {
  position: absolute;
  top: 5px;
  width: 48px;
  text-align: center;
}
.label {
  color: #5e6674;
  font-size: 14px;
}
</style>
