<template>
  <flowy-drag-handle class="grabbable mb-3">
    <b-card class="card-preview">
      <div class="d-flex flex-row align-items-center">
        <icon :name="type" />
        <div class="ml-3">{{ $t(typeName) }}</div>
      </div>
    </b-card>
  </flowy-drag-handle>
</template>

<i18n src="@/locales/common/form-field-type-names.json"></i18n>

<script>
export default {
  props: {
    typeName: {
      type: String
    },
    type: {
      type: String
    }
  }
};
</script>

<style lang="css" scoped>
.card-preview {
  background: #f4f5f6;
  border: none;
  margin-bottom: 6px;
}
</style>
