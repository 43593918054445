<template>
  <div class="d-flex flex-row" style="min-width: 536px;">
    <b-col class="p-0 mr-2" style="width:260px;">
      <b-row
        no-gutters
        v-for="(option, index) in oddOptions"
        :key="index"
        class="mb-2"
      >
        <b-col>
          <b-form-group v-if="allowMultipleAnswer" class="mb-0">
            <div class="choice-option">
              <b-form-checkbox :checked="singleAnswer" :value="option.value">
                {{ option.value }}
              </b-form-checkbox>
            </div>
          </b-form-group>

          <b-form-group v-else class="mb-0">
            <div class="choice-option">
              <b-form-radio :checked="singleAnswer" :value="option.value" plain>
                <span>{{ option.value }}</span>
              </b-form-radio>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
    <b-col class="p-0" style="width:260px;">
      <b-row
        no-gutters
        v-for="(option, index) in evenOptions"
        :key="index"
        class="mb-2"
      >
        <b-col>
          <b-form-group v-if="allowMultipleAnswer" class="mb-0">
            <div class="choice-option">
              <b-form-checkbox :checked="singleAnswer" :value="option.value">
                {{ option.value }}
              </b-form-checkbox>
            </div>
          </b-form-group>

          <b-form-group v-else class="mb-0">
            <div class="choice-option">
              <b-form-radio :checked="singleAnswer" :value="option.value" plain>
                <span>{{ option.value }}</span>
              </b-form-radio>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
  </div>
</template>

<script>
export default {
  props: {
    answers: {
      type: Array
    },
    options: {
      type: Array
    },
    allowMultipleAnswer: {
      type: Boolean
    }
  },
  computed: {
    evenOptions() {
      return this.options.filter((item, index) => index % 2);
    },
    oddOptions() {
      return this.options.filter((item, index) => !(index % 2));
    },
    singleAnswer() {
      return this.answers[0].answer;
    },
    multipleAnswer() {
      return this.answers;
    }
  }
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: #3881d7 !important;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.selected-wrapper {
  border: 1px solid #3881d7 !important;
}

.choice-option {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 200px;
  padding: 6px 12px;
  background-color: #e8f1fb;
  color: #5e6674;
  border-radius: 4px;

  .bullet {
    width: 16px;
    height: 16px;
    background-color: #e8f1fb;
    color: #5e6674;
    border: 1px solid #5e6674;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .choice-label {
    margin-left: 8px;
  }
  .custom-control {
    padding-left: 0px;
  }
}
</style>
