<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row" style="min-width: 536px;">
      <b-col class="p-0 mr-2" style="width:260px;">
        <b-row
          no-gutters
          v-for="(option, index) in oddOptions"
          :key="index"
          class="mb-2"
        >
          <b-col>
            <b-form-group class="mb-0">
              <div
                class="choice-option quiz"
                :class="{
                  'correct-answer-option': isCorrectAnswer(option.value),
                  'wrong-answer-option': !isCorrectAnswer(option.value),
                  checked: isTheAnswer(option.value)
                }"
                :style="option.file ? 'height: 150px' : ''"
              >
                <b-form-checkbox :checked="shouldChecked(option.value)">
                  <div class="image-wrapper" v-if="option.file">
                    <b-img
                      style="max-height: 150px"
                      class="w-100 h-100 m-auto"
                      :src="buildImageLink(option.file.key)"
                    ></b-img>
                  </div>
                  <div v-else>
                    {{ option.value }}
                  </div>
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="p-0" style="width:260px;">
        <b-row
          no-gutters
          v-for="(option, index) in evenOptions"
          :key="index"
          class="mb-2"
        >
          <b-col>
            <b-form-group class="mb-0">
              <div
                class="choice-option quiz"
                :class="{
                  'correct-answer-option': isCorrectAnswer(option.value),
                  'wrong-answer-option': !isCorrectAnswer(option.value),
                  checked: isTheAnswer(option.value)
                }"
                :style="option.file ? 'height: 150px' : ''"
              >
                <b-form-checkbox :checked="shouldChecked(option.value)">
                  <div class="image-wrapper" v-if="option.file">
                    <b-img
                      style="max-height: 150px"
                      class="w-100 h-100"
                      :src="buildImageLink(option.file.key)"
                    ></b-img>
                  </div>
                  <div v-else>
                    {{ option.value }}
                  </div>
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </div>
    <div>
      <span class="text-description"
        >{{ $t("correctAnswer") }}
        {{ correctAnswer.map(item => '"' + item + '"').join(", ") }}</span
      >
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "correctAnswer": "Correct answer are"
  },
  "id": {
    "correctAnswer": "Jawaban benar adalah"
  }
}
</i18n>

<script>
export default {
  props: {
    answers: {
      type: Array
    },
    options: {
      type: Array
    },
    allowMultipleAnswer: {
      type: Boolean
    },
    correctAnswer: {
      type: Array
    }
  },
  computed: {
    evenOptions() {
      return this.options.filter((item, index) => index % 2);
    },
    oddOptions() {
      return this.options.filter((item, index) => !(index % 2));
    },
    singleAnswer() {
      return this.answers[0].answer;
    },
    multipleAnswer() {
      return this.answers;
    }
  },
  methods: {
    shouldChecked(value) {
      if (this.allowMultipleAnswer) {
        return (
          this.correctAnswer.includes(value) ||
          this.answers[0].answer.includes(value)
        );
      } else {
        return (
          this.correctAnswer[0] === value ||
          this.answers[0].answer.includes(value)
        );
      }
    },
    isCorrectAnswer(value) {
      if (this.allowMultipleAnswer) {
        return this.correctAnswer.includes(value);
      } else {
        return this.correctAnswer[0] === value;
      }
    },
    isTheAnswer(value) {
      return this.answers[0].answer.includes(value);
    },
    buildImageLink(key) {
      return `https://${process.env.VUE_APP_BUCKET_NAME}.s3.${process.env.VUE_APP_AWS_REGION}.amazonaws.com/${key}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: #3881d7 !important;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.selected-wrapper {
  border: 1px solid #3881d7 !important;
}

.choice-option {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 150px;
  min-width: 200px;
  padding: 6px 12px;
  border: 1px solid #d9dde1;
  color: #5e6674;
  border-radius: 4px;

  .bullet {
    width: 16px;
    height: 16px;
    background-color: #e8f1fb;
    color: #5e6674;
    border: 1px solid #5e6674;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .choice-label {
    margin-left: 8px;
  }

  .custom-control {
    padding-left: 0px;
  }

  .image-wrapper {
    height: 130px;
  }
}
</style>
