<template>
  <div class="d-flex justify-content-center align-items-center h-100">
    <div
      v-if="!isFormPublished"
      class="d-flex justify-content-center align-items-center flex-column m-auto"
    >
      <icon name="not-published" />
      <h4>{{ $t("formNotPublishedYet") }}</h4>
      <span>{{ $t("publishYourForm2") }}</span>
      <b-button class="mt-2" variant="primary" @click="goToWorkspace">{{
        $t("goToWorkspace")
      }}</b-button>
    </div>

    <b-container fluid class="p-0" v-if="!isLoading && isFormPublished">
      <b-row class="w-100" style="height: 168px;" no-gutters>
        <b-col class="bg-white">
          <div style="max-width: 754px;" class="m-auto">
            <b-row class="mb-2 mt-4" no-gutters>
              <b-col cols="6" class="m-auto d-flex flex-row">
                <span style="fontSize: 18px; fontWeight: bold;">{{
                  $t("currentResult")
                }}</span>
                <div
                  :class="
                    selectedForm.acceptingResponse
                      ? 'badge-primary'
                      : 'badge-danger'
                  "
                  class="ml-4"
                >
                  {{
                    selectedForm.acceptingResponse
                      ? $t("acceptingResponse")
                      : $t("notAcceptingResponse")
                  }}
                </div>
              </b-col>
              <b-col cols="6">
                <div class="d-flex align-items-center justify-content-end">
                  <div class="badge-secondary">
                    <b-icon
                      v-b-tooltip.hover.bottom="
                        selectedForm.acceptingResponse
                          ? $t('formAcceptingResponse')
                          : $t('formNotAcceptingResponse')
                      "
                      @click="onSwitchAllowResponse"
                      icon="inbox-fill"
                      style="width: 18px; height: 18px;"
                      class="mr-2 cursor-pointer"
                    />
                    <div v-if="!isFormTemplateQuiz">
                      <span
                        v-if="selectedForm.acceptingResponse === true"
                        class="cursor-pointer"
                        @click="onSwitchAllowResponse"
                        >{{ $t("closeForm") }}</span
                      >
                      <span
                        v-if="selectedForm.acceptingResponse === false"
                        class="cursor-pointer"
                        @click="onSwitchAllowResponse"
                        >{{ $t("openForm") }}</span
                      >
                    </div>
                    <div v-else>
                      <span
                        v-if="selectedForm.acceptingResponse === true"
                        class="cursor-pointer"
                        @click="onSwitchAllowResponse"
                        >{{ $t("closeQuiz") }}</span
                      >
                      <span
                        v-if="selectedForm.acceptingResponse === false"
                        class="cursor-pointer"
                        @click="onSwitchAllowResponse"
                        >{{ $t("openQuiz") }}</span
                      >
                    </div>
                  </div>
                  <div class="badge-secondary ml-2">
                    <b-icon
                      v-b-tooltip.hover.bottom="$t('downloadSummary')"
                      class="cursor-pointer"
                      icon="download"
                      style="width: 18px; height: 18px;"
                      @click="downloadExcel"
                    ></b-icon>
                    <span class="ml-2 cursor-pointer" @click="downloadExcel">{{
                      $t("downloadResponses")
                    }}</span>
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-row no-gutters>
              <b-col class="m-auto d-flex flex-column">
                <b-row class="d-flex flex-row">
                  <b-col cols="3" md="auto" class="d-flex flex-column mr-2">
                    <h5 class="m-0">{{ datePublished }}</h5>
                    <span class="text-description">{{
                      $t("latestPublished")
                    }}</span>
                  </b-col>
                  <b-col
                    v-if="!isFormTemplateQuiz"
                    cols="3"
                    md="auto"
                    class="d-flex flex-column mr-2"
                  >
                    <h5 class="m-0">{{ views }}</h5>
                    <span class="text-description">{{ $t("views") }}</span>
                  </b-col>
                  <b-col
                    v-if="!isFormTemplateQuiz"
                    cols="3"
                    md="auto"
                    class="d-flex flex-column"
                  >
                    <h5 class="m-0">{{ responseCount }}</h5>
                    <span class="text-description">{{ $t("responses") }}</span>
                  </b-col>

                  <b-col
                    v-if="isFormTemplateQuiz"
                    cols="3"
                    md="auto"
                    class="d-flex flex-column mr-2"
                  >
                    <h5 class="m-0">{{ responseCount }}</h5>
                    <span class="text-description">{{
                      $t("participant")
                    }}</span>
                  </b-col>
                  <b-col
                    v-if="isFormTemplateQuiz"
                    cols="3"
                    md="auto"
                    class="d-flex flex-column"
                  >
                    <h5 class="m-0">{{ correctAnswerAverage }}%</h5>
                    <span class="text-description">{{
                      $t("avgCorrectAnswer")
                    }}</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row class="response-tabs">
              <b-col class="m-auto d-flex flex-column">
                <b-row>
                  <b-col cols="12" md="auto">
                    <b-nav>
                      <b-nav-item
                        v-if="isFormTemplateQuiz"
                        @click.prevent="goToLeaderboard"
                        :active="isLeaderboard"
                        >{{ $t("leaderboard") }}
                      </b-nav-item>
                      <b-nav-item
                        @click.prevent="goToSummary"
                        :active="isSummary"
                        >{{ $t("summary") }}
                      </b-nav-item>
                      <b-nav-item
                        @click.prevent="goToDetails"
                        :active="isDetails"
                        >{{ $t("details") }}
                      </b-nav-item>
                    </b-nav>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>

      <b-row class="summary-wrapper">
        <b-col>
          <router-view ref="summary" />
        </b-col>
      </b-row>
    </b-container>
    <div
      v-if="isLoading"
      class="d-flex justify-content-center align-items-center"
      :style="{
        width: '20px',
        height: '20px'
      }"
    >
      <b-icon-arrow-clockwise animation="spin" />
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "formNotPublishedYet": "Sorry, it seems your form is not published yet",
    "publishYourForm2": "Please publish your form first",
    "goToWorkspace": "Go To Workspace",
    "currentResult": "Current Result",
    "acceptingResponse": "Accepting Response",
    "latestPublished": "Latest Published",
    "views": "Views",
    "responses": "Responses",
    "participant": "Participant",
    "acceptingResponse": "Accepting Responses",
    "notAcceptingResponse": "Not accepting responses",
    "formAcceptingResponse": "This form is accepting responses",
    "formNotAcceptingResponse": "This form is NOT accepting responses",
    "downloadSummary": "Download Summary",
    "downloadResponses": "Download Responses",
    "closeForm": "Close form now",
    "openForm": "Open form now",
    "closeQuiz": "Close quiz now",
    "openQuiz": "Open quiz now",
    "avgCorrectAnswer": "Avg. correct answer",
    "succeedSetAcceptingResponse": "Form accepting response changed",
    "leaderboard": "Leaderboard",
    "summary": "Summary",
    "details": "Details"
  },
  "id": {
     "formNotPublishedYet": "Maaf, sepertinya formulir Anda belum diterbitkan",
     "publishYourForm2": "Harap terbitkan formulir anda terlebih dahulu",
     "goToWorkspace": "Buka Ruang Kerja",
     "currentResult": "Hasil saat ini",
     "acceptingResponse": "Menerima tanggapan",
     "latestPublished": "Publikasi terakhir",
     "views": "Dilihat",
     "responses": "Tanggapan",
     "participant": "Peserta",
     "acceptingResponse": "Menerima tanggapan",
     "notAcceptingResponse": "Tidak menerima tanggapan",
     "formAcceptingResponse": "Formulir ini menerima tanggapan",
     "formNotAcceptingResponse": "Formulir ini TIDAK menerima tanggapan",
     "downloadSummary": "Unduh Ringkasan",
     "downloadResponses": "Unduh tanggapan",
     "closeForm": "Tutup formulir",
     "openForm": "Buka formulir",
     "openQuiz": "Buka kuis",
     "closeQuiz": "Tutup kuis",
     "avgCorrectAnswer": "Rata-rata jawaban benar",
     "succeedSetAcceptingResponse": "Formulir menerima tanggapan diubah",
     "leaderboard": "Papan Peringkat",
     "summary": "Ringkasan",
     "details": "Detail"
  }
}
</i18n>

<script>
import { mapActions, mapGetters } from "vuex";
import { cloneDeep } from "lodash";
import moment from "moment";
import formFieldTypes from "@/constants/formFieldTypes";
import formFieldTypesQuiz from "@/constants/formFieldTypesQuiz";
import { formField } from "@aws-amplify/ui";

const ExcelJS = require("exceljs");

export default {
  props: {
    teamId: {
      type: String,
      required: true
    },
    workspaceId: {
      type: String,
      required: true
    },
    formId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedForm: {},
      fields: [],
      responses: [],
      isLoading: false,
      sumCorrectAnswer: 0,
      sumWrongAnswer: 0
    };
  },
  computed: {
    ...mapGetters("form", [
      "form",
      "forms",
      "selectedVersion",
      "response",
      "findForm"
    ]),

    isSummary() {
      return this.$route.name === "FormSummary";
    },

    isLeaderboard() {
      return this.$route.name === "FormLeaderboard";
    },

    isDetails() {
      return this.$route.name === "FormDetails";
    },

    isFormPublished() {
      return !!this.findForm(this.formId, "PUBLISHED");
    },

    isFormTemplateQuiz() {
      return this.form && this.form.template && this.form.template === "quiz";
    },

    datePublished() {
      return moment(this.selectedForm.latestPublished).format("DD MMM YYYY");
    },

    views() {
      let views = 0;
      if (this.selectedForm.totalViews) {
        return (views = this.selectedForm.totalViews);
      } else {
        return views;
      }
    },

    responseCount() {
      if (this.response) {
        return this.response.length;
      }
      return 0;
    },

    correctAnswerAverage() {
      return (
        Math.floor(
          (this.sumCorrectAnswer /
            (this.sumCorrectAnswer + this.sumWrongAnswer)) *
            100
        ) || 0
      );
    }
  },
  async mounted() {
    this.isLoading = true;
    this.setSelectedForm({
      formId: this.formId,
      version: this.isFormPublished ? "PUBLISHED" : "DRAFT"
    });
    await this.getFormResponse({
      teamId: this.teamId,
      workspaceId: this.workspaceId,
      formId: this.formId
    });
    this.getAllResponses();
    this.isLoading = false;
    for (const singleResponse of this.response) {
      this.sumCorrectAnswer += singleResponse.data.totalCorrectAnswer;
      this.sumWrongAnswer += singleResponse.data.totalWrongAnswer;
    }
  },
  methods: {
    ...mapActions("form", [
      "getFormResponse",
      "setSelectedVersion",
      "getForm",
      "setAcceptingResponse",
      "setSelectedForm"
    ]),

    goToDetails() {
      if (this.$route.name === "FormDetails") {
        return;
      } else {
        this.$router.push({ name: "FormDetails" });
      }
    },
    goToSummary() {
      if (this.$route.name === "FormSummary") {
        return;
      } else {
        this.$router.push({ name: "FormSummary" });
      }
    },
    goToLeaderboard() {
      if (this.$route.name === "FormLeaderboard") {
        return;
      } else {
        this.$router.push({ name: "FormLeaderboard" });
      }
    },
    goToWorkspace() {
      this.$router.push({ name: "Workspace" });
    },
    async onSwitchAllowResponse() {
      await this.setAcceptingResponse({
        teamId: this.teamId,
        workspaceId: this.workspaceId,
        formId: this.formId,
        value: !this.selectedForm.acceptingResponse,
        vm: this
      });
    },
    getAllResponses() {
      for (const singleResponse of this.response) {
        this.responses = [...this.responses, ...singleResponse.data.responses];
      }
    },
    async downloadSummary() {
      const workbook = new ExcelJS.Workbook();
      workbook.creator = "Survein";
      workbook.lastModifiedBy = "Survein";
      workbook.created = new Date();

      const worksheet = workbook.addWorksheet("Summary");
      let index = 1;
      for (const field of this.fields) {
        const row = worksheet.addRow([index, field.title, "", ""]);
        row.eachCell(cell => {
          cell.font = {
            bold: true
          };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFEFEFEF" }
          };
        });
        worksheet.mergeCells(row.getCell(2)._address, row.getCell(4)._address);
        if (field.description !== "") {
          const descRow = worksheet.addRow(["", field.title, "", ""]);
          descRow.eachCell(cell => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFEFEFEF" }
            };
          });
          worksheet.mergeCells(
            descRow.getCell(2)._address,
            descRow.getCell(4)._address
          );
        }
        const row1 = worksheet.addRow([
          "",
          this.buildAnswerDesc(field.type),
          "Response count",
          this.buildPercentTitle(field.type)
        ]);
        row1.eachCell(cell => {
          cell.font = {
            italic: true
          };
        });
        if (
          [formFieldTypes.MULTISELECT, formFieldTypes.DROPDOWN].includes(
            field.type
          )
        ) {
          for (const option of field.options) {
            worksheet.addRow([
              "",
              option.value,
              this.optionCount(option.value, this.getAnswer(field.id)),
              field.type === formFieldTypes.DROPDOWN
                ? this.dropdownPercentage(option.value, field.id) + "%"
                : this.optionPercentage(
                    option.value,
                    this.getAnswer(field.id)
                  ) + "%"
            ]);
          }
        }
        if ([formFieldTypes.SCALE].includes(field.type)) {
          for (let i = 1; i <= field.scaleStep; i++) {
            worksheet.addRow([
              "",
              i,
              this.scaleCount(i, field.id),
              this.scalePercentage(i, field.id) + "%"
            ]);
          }
        }
        if (
          [
            formFieldTypes.SHORT_TEXT,
            formFieldTypes.LONG_TEXT,
            formFieldTypes.DATE,
            formFieldTypes.EMAIL,
            formFieldTypes.NUMBER,
            formFieldTypes.TEL,
            formFieldTypes.FILE_UPLOAD
          ].includes(field.type)
        ) {
          worksheet.addRow([
            "",
            "Please check all responses in Responses tab",
            this.getAnswer(field.id).length
          ]);
        }
        worksheet.getColumn(1).width = 5;
        worksheet.getColumn(2).width = 40;
        worksheet.getColumn(3).width = 15;
        worksheet.getColumn(4).width = 15;
        index++;
      }

      const worksheet2 = workbook.addWorksheet("Responses");
      worksheet2.columns = [
        { header: "Timestamp", key: "Timestamp", width: 40 },
        ...this.fields.map(field => ({
          header: field.title,
          key: field.id,
          width: 50
        }))
      ];
      const firstResponseRow = worksheet2.getRow(1);
      firstResponseRow.eachCell(cell => {
        cell.font = {
          bold: true
        };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFEFEFEF" }
        };
      });
      for (const field of this.fields) {
        const col = worksheet2.getColumn(field.id);
        col.values = [
          col.values[1],
          ...this.responses
            .filter(response => response.id === field.id)
            .map(answer => {
              if (field.type === formFieldTypes.TEL) {
                return (
                  (answer.selectedCountry
                    ? answer.selectedCountry.countryCallingCodes
                    : "") + answer.answer
                );
              }

              if (field.allowMultipleAnswer === true) {
                return answer.answer.toString().replace(/,/g, ", ");
              }

              if (field.type === formFieldTypes.FILE_UPLOAD) {
                if (answer.files && answer.files.length) {
                  const link = answer.files
                    .map(
                      file =>
                        `https://${process.env.VUE_APP_BUCKET_NAME}.s3.${process.env.VUE_APP_AWS_REGION}.amazonaws.com/${file.key}`
                    )
                    .toString()
                    .replace(/,/g, "\n");
                  return link;
                }
              }

              return answer.answer;
            })
        ];
      }
      const timeStampCol = worksheet2.getColumn("Timestamp");
      timeStampCol.values = [
        timeStampCol.values[1],
        ...this.response.map(
          response => new Date(response.data.createdAt).toString() || ""
        )
      ];
      const buffer = await workbook.xlsx.writeBuffer();
      this.downloadBlob(buffer, "summary.xlsx", "application/octet-stream");
    },
    async downloadSummaryQuiz() {
      const workbook = new ExcelJS.Workbook();
      workbook.creator = "Survein";
      workbook.lastModifiedBy = "Survein";
      workbook.created = new Date();

      const worksheet = workbook.addWorksheet("Summary");
      let index = 1;
      for (const field of this.fields) {
        let row;
        if (field.type === formFieldTypesQuiz.START_PAGE) {
          row = worksheet.addRow([index, field.title, "", ""]);
        } else {
          row = worksheet.addRow([
            index,
            field.description.replace(/<[^>]+>/g, ""),
            "",
            ""
          ]);
        }
        row.eachCell(cell => {
          cell.font = {
            bold: true
          };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFEFEFEF" }
          };
        });
        worksheet.mergeCells(row.getCell(2)._address, row.getCell(4)._address);

        let row1;
        if (field.type === formFieldTypesQuiz.START_PAGE) {
          row1 = worksheet.addRow([
            "",
            field.description.replace(/<[^>]+>/g, ""),
            "",
            ""
          ]);
        } else {
          row1 = worksheet.addRow(["", "Answer options", "Answer count", ""]);
        }

        row1.eachCell(cell => {
          cell.font = {
            italic: true
          };
        });
        if (
          [
            formFieldTypesQuiz.MULTISELECT_QUIZ,
            formFieldTypesQuiz.TRUE_FALSE
          ].includes(field.type)
        ) {
          for (const option of field.options) {
            worksheet.addRow([
              "",
              option.value,
              this.optionCount(option.value, this.getAnswer(field.id)),
              field.type === formFieldTypesQuiz.TRUE_FALSE
                ? this.trueFalsePercentage(
                    option.label,
                    this.getAnswer(field.id)
                  ) + "%"
                : this.optionPercentageQuiz(
                    option.value,
                    this.getAnswer(field.id)
                  ) + "%"
            ]);
          }
        }
        worksheet.getColumn(1).width = 5;
        worksheet.getColumn(2).width = 40;
        worksheet.getColumn(3).width = 15;
        worksheet.getColumn(4).width = 15;
        index++;
      }

      const worksheet2 = workbook.addWorksheet("Responses");
      worksheet2.columns = [
        { header: "Timestamp", key: "Timestamp", width: 40 },
        ...this.fields.map(field => ({
          header:
            field.type === formFieldTypesQuiz.START_PAGE
              ? field.title
              : field.description.replace(/<[^>]+>/g, ""),
          key: field.id,
          width: 50
        })),
        { header: "Total score", key: "Total score", width: 40 }
      ];
      const firstResponseRow = worksheet2.getRow(1);
      firstResponseRow.eachCell(cell => {
        cell.font = {
          bold: true
        };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFEFEFEF" }
        };
      });
      for (const field of this.fields) {
        const col = worksheet2.getColumn(field.id);
        col.values = [
          col.values[1],
          ...this.responses
            .filter(response => response.id === field.id)
            .map(answer => {
              if (field.allowMultipleAnswer === true) {
                return answer.answer.toString().replace(/,/g, ", ");
              }
              if (field.type === formFieldTypesQuiz.TRUE_FALSE) {
                return answer.answer === "a" ? "True" : "False";
              }
              return answer.answer;
            })
        ];
      }
      const timeStampCol = worksheet2.getColumn("Timestamp");
      timeStampCol.values = [
        timeStampCol.values[1],
        ...this.response.map(
          response => new Date(response.data.createdAt).toString() || ""
        )
      ];
      const colTotalScore = worksheet2.getColumn("Total score");
      colTotalScore.values = [
        colTotalScore.values[1],
        ...this.response.map(response => response.data.totalScore)
      ];

      const buffer = await workbook.xlsx.writeBuffer();
      this.downloadBlob(buffer, "summary.xlsx", "application/octet-stream");
    },
    scaleCount(value, id) {
      const answers = this.responses.filter(
        response => response.id === id && response.answer === value
      );
      return answers.length;
    },
    scalePercentage(value, id) {
      const count = this.scaleCount(value, id);
      const answers = this.responses.filter(
        response => response.id === id && response.answer !== ""
      );
      if (answers.length) {
        return Math.floor((count / answers.length) * 100);
      } else {
        return 0;
      }
    },
    optionCount(value, answers) {
      let count = 0;
      for (const answer of answers) {
        if (answer.answer.includes(value)) {
          count++;
        }
      }
      return count;
    },
    optionPercentage(value, answers) {
      let count = 0;
      if (answers.length) {
        for (const answer of answers) {
          if (answer.answer.includes(value)) {
            count++;
          }
        }
        return Math.floor((count / answers.length) * 100);
      } else {
        return 0;
      }
    },
    dropdownPercentage(value, id) {
      const answers = this.responses.filter(
        response => response.id === id && response.answer === value
      );
      const allResponse = this.responses.filter(response => response.id === id);
      if (answers.length) {
        return Math.floor((answers.length / allResponse.length) * 100);
      } else {
        return 0;
      }
    },
    trueFalsePercentage(value, answers) {
      let count = 0;
      if (answers.length) {
        for (const answer of answers) {
          if (answer.answer === value.value) {
            count++;
          }
        }
        return Math.floor((count / answers.length) * 100);
      } else {
        return 0;
      }
    },
    optionPercentageQuiz(value, answers) {
      let count = 0;
      if (answers.length) {
        for (const answer of answers) {
          if (answer.answer === value || answer.answer.includes(value)) {
            count++;
          }
        }
        return Math.floor((count / answers.length) * 100);
      } else {
        return 0;
      }
    },
    downloadURL(data, fileName) {
      const a = document.createElement("a");
      a.href = data;
      a.download = fileName;
      document.body.appendChild(a);
      a.style.display = "none";
      a.click();
      a.remove();
    },
    downloadBlob(data, fileName, mimeType) {
      const blob = new Blob([data], {
        type: mimeType
      });
      const url = window.URL.createObjectURL(blob);
      this.downloadURL(url, fileName);
      setTimeout(() => window.URL.revokeObjectURL(url), 1000);
    },
    buildAnswerDesc(type) {
      if (
        [
          formFieldTypes.MULTISELECT,
          formFieldTypes.DROPDOWN,
          formFieldTypes.MULTISELECT_QUIZ,
          formFieldTypes.TRUE_FALSE
        ].includes(type)
      ) {
        return "Answer options";
      }
      if ([formFieldTypes.SCALE].includes(type)) {
        return "Scale";
      }
      if (
        [
          formFieldTypes.SHORT_TEXT,
          formFieldTypes.LONG_TEXT,
          formFieldTypes.DATE,
          formFieldTypes.EMAIL,
          formFieldTypes.NUMBER,
          formFieldTypes.TEL
        ].includes(type)
      ) {
        return "Answer options";
      }
    },
    buildPercentTitle(type) {
      if (
        [
          formFieldTypes.MULTISELECT,
          formFieldTypes.DROPDOWN,
          formFieldTypes.SCALE
        ].includes(type)
      ) {
        return "Response percent";
      } else if (
        [formField.MULTISELECT_QUIZ, formFieldTypes.TRUE_FALSE].includes(type)
      ) {
        return "Answer percent";
      } else {
        return "";
      }
    },
    getAnswer(fieldId) {
      const answers = this.responses.filter(
        response => response.id === fieldId
      );
      return answers.filter(
        answer => answer.answer !== "" || (answer.files && answer.files.length)
      );
    },
    downloadExcel() {
      if (this.isFormTemplateQuiz) {
        this.downloadSummaryQuiz();
      } else {
        this.downloadSummary();
      }
    }
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler() {
        this.setSelectedVersion("PUBLISHED");
        this.selectedForm = cloneDeep(this.form);
        if (this.selectedForm) {
          this.fields = this.selectedForm.fields;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.nav-item {
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: #343a40;
    height: 47px;
    &.active {
      color: #007bff;
      border-bottom: 4px solid #007bff;
    }
  }
}

.summary-wrapper {
  height: calc(100vh - 216px);
  overflow: auto;
  padding: 24px 24px 16px 24px;
}

.response-tabs {
  position: absolute;
  bottom: 0;
}

.not-published {
  width: 100vw;
  height: calc(100vh - 47px);
  background-color: white;
}

.badge-primary {
  padding: 3px 4px;
  background-color: #e8f1fb;
  border-radius: 4px;
  color: #166ed8;
}

.badge-danger {
  padding: 3px 4px;
  background-color: #feecea;
  border-radius: 4px;
  color: #eb5757;
}

.badge-secondary {
  padding: 4px 8px;
  background-color: #f4f5f7;
  border-radius: 4px;
  color: black;
  display: flex;
  align-items: center;
}
</style>
