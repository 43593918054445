<template>
  <div>
    <div
      v-if="!responses"
      class="d-flex justify-content-center align-items-center flex-column"
      style="margin-top: 130px"
    >
      <icon name="no-response" />
      <h4>{{ $t("noResponseYet") }}</h4>
      <span style="width: 350px; text-align: center">{{
        $t("publishYourForm")
      }}</span>
    </div>

    <b-row v-else>
      <b-col>
        <b-card
          style="min-height: 56px; maxWidth:754px"
          class="ml-auto mr-auto mb-2 card-border"
        >
          <b-row>
            <b-col cols="auto" class="pr-0">
              <div
                class="date-selector"
                @click="openDateFilter = !openDateFilter"
                :class="{ active: openDateFilter }"
              >
                <div class="text">{{ determineDateText }}</div>
                <icon name="calendar" />
                <div class="date-range" v-show="openDateFilter">
                  <div class="days-selector">
                    <div
                      :class="{ active: isFilterApplied('all-time') }"
                      @click.stop="filterOption = 'all-time'"
                    >
                      All time
                    </div>
                    <div
                      :class="{ active: isFilterApplied('today') }"
                      @click.stop="filterOption = 'today'"
                    >
                      Today
                    </div>
                    <div
                      :class="{ active: isFilterApplied('seven-days') }"
                      @click.stop="filterOption = 'seven-days'"
                    >
                      Last 7 days
                    </div>
                    <div
                      :class="{ active: isFilterApplied('fourteen-days') }"
                      @click.stop="filterOption = 'fourteen-days'"
                    >
                      Last 14 days
                    </div>
                  </div>
                  <div class="calendar-selector">
                    <div class="calendar-wrapper" @click.stop>
                      <vc-date-picker
                        v-model="range"
                        is-range
                        :min-date="new Date(form.createdAt)"
                        :max-date="new Date()"
                      />
                    </div>
                    <div class="calendar-footer">
                      <b-button variant="light">
                        Cancel
                      </b-button>
                      <b-button
                        variant="primary"
                        class="ml-2"
                        @click="setResponseFilter()"
                      >
                        Apply
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col cols="6" class="pl-0">
              <div
                class="d-flex justify-content-center align-items-center flex-row h-100"
              >
                <b-icon
                  icon="chevron-left"
                  role="button"
                  @click="previousIndex()"
                />
                <span class="ml-2 mr-1">Respondent no.</span>
                <b-input
                  v-model="selectedIndex"
                  style="max-width: 65px; max-height: 28px; text-align: center"
                />
                <span class="ml-1 mr-2"
                  >{{ $t("from") }} {{ allResponses.length }}</span
                >
                <b-icon
                  icon="chevron-right"
                  role="button"
                  @click="nextIndex()"
                />
              </div>
            </b-col>
          </b-row>
        </b-card>

        <div v-if="allResponses.length">
          <div class="total-point" v-if="form.template === 'quiz'">
            <span
              >{{ response[selectedIndex - 1].data.totalScore }} points ({{
                response[selectedIndex - 1].data.totalCorrectAnswer
              }}
              of
              {{
                response[selectedIndex - 1].data.totalCorrectAnswer +
                  response[selectedIndex - 1].data.totalWrongAnswer
              }}
              correct)</span
            >
          </div>

          <b-card
            style="min-height: 168px; maxWidth:754px"
            class="ml-auto mr-auto mb-2 card-border"
            v-for="response in responses.data.responses"
            :key="response.id"
          >
            <b-row no-gutters class="mt-3">
              <b-col
                v-if="
                  response.type !== 'MultipleChoiceQuizType' &&
                    response.type !== 'TrueFalseType'
                "
                cols="9"
                class="m-auto"
              >
                <h5 class="text-center" v-if="response.title">
                  {{ response.title }}
                </h5>
                <h5 v-else>...</h5>
                <span
                  class="text-description"
                  v-html="response.description"
                ></span>
              </b-col>
              <b-col
                v-if="
                  response.type === 'MultipleChoiceQuizType' ||
                    response.type === 'TrueFalseType'
                "
                cols="9"
                class="m-auto"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <div v-show="response.type !== 'StartPageType'">
                    <div
                      class="d-flex flex-row mb-3"
                      v-if="
                        isCorrectAnswer(
                          response.type,
                          response.answer,
                          response.correctAnswer
                        )
                      "
                    >
                      <icon name="correct" />
                      <span class="ml-2">Correct</span>
                    </div>
                    <div class="d-flex flex-row mb-3" v-else>
                      <icon name="incorrect" />
                      <span class="ml-2">Incorrect</span>
                    </div>
                  </div>
                  <div class="d-flex flex-row">
                    <span class="mr-2"
                      >{{ response.answerScore }} /
                      {{ response.maxScore }}</span
                    >
                    <icon name="trophy" />
                  </div>
                </div>
                <h5 v-html="response.description" class="m-0"></h5>
              </b-col>
            </b-row>
            <b-row no-gutters class="mt-2 mb-2 w-536">
              <component
                :is="response.type"
                :answers="[
                  {
                    answer: response.answer,
                    id: response.id,
                    selectedCountry: response.selectedCountry
                  }
                ]"
                :label="response.label"
                :options="response.options"
                :allowMultipleAnswer="response.allowMultipleAnswer"
                :allFiles="response.files"
                :correctAnswer="response.correctAnswer"
                :scaleStep="response.scaleStep"
              />
            </b-row>
          </b-card>
        </div>

        <div class="mt-5" v-else>
          <icon name="no-response" />
          <h4 class="text-center">{{ $t("noResponseOnDate") }}</h4>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<i18n>
{
  "en": {
    "noResponseYet": "No responses yet",
    "noResponseOnDate": "No responses on selected date",
    "publishYourForm": "Publish the form & share to your circle, and you will see their responses here.",
    "respondent": "Respondent no.",
    "from": "from"
  },
  "id": {
     "noResponseYet": "Belum mendapatkan tanggapan",
     "noResponseOnDate": "Tidak ada tanggapan di tanggal tersebut",
     "publishYourForm": "Publikasikan formulir & bagikan ke sekitar Anda, dan Anda akan melihat tanggapan mereka di sini.",
     "respondent": "Responden no.",
     "from": "dari"
  }
}
</i18n>

<script>
import StartPageType from "@/components/FormResponse/Summary/StartPage";
import EmailType from "@/components/FormResponse/Summary/Email";
import PhoneType from "@/components/FormResponse/Summary/Telephone";
import LongTextType from "@/components/FormResponse/Summary/LongText";
import ShortTextType from "@/components/FormResponse/Summary/ShortText";
import DropdownDateType from "@/components/FormResponse/Summary/Date";
import StatementType from "@/components/FormResponse/Summary/Statement";
import NumberType from "@/components/FormResponse/Summary/Number";
import ScaleType from "@/components/FormResponse/Details/Scale";
import MultipleChoiceType from "@/components/FormResponse/Details/Multiple";
import DropdownType from "@/components/FormResponse/Details/Dropdown";
import FileUploadType from "@/components/FormResponse/Details/UploadFile";
import MultipleChoiceQuizType from "@/components/FormResponse/Details/MultipleQuiz";
import TrueFalseType from "@/components/FormResponse/Details/TrueFalse";
import { startOfDay, endOfDay, subDays, differenceInDays } from "date-fns";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { cloneDeep } from "lodash";

export default {
  components: {
    StartPageType,
    EmailType,
    PhoneType,
    LongTextType,
    ShortTextType,
    DropdownDateType,
    StatementType,
    NumberType,
    ScaleType,
    MultipleChoiceType,
    DropdownType,
    FileUploadType,
    MultipleChoiceQuizType,
    TrueFalseType
  },
  props: {
    teamId: {
      type: String,
      required: true
    },
    workspaceId: {
      type: String,
      required: true
    },
    formId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedIndex: 1,
      responses: {},
      allResponses: [],
      openDateFilter: false,
      filterOption: "all-time",
      range: {
        start: new Date(2020, 0, 1),
        end: new Date(2020, 0, 5)
      }
    };
  },
  computed: {
    ...mapGetters("form", [
      "form",
      "forms",
      "selectedVersion",
      "response",
      "responseByIndex"
    ]),
    filterResponse() {
      return this.responses.data.filter(answer => answer.answer !== "");
    },
    determineDateText() {
      if (this.range.start === null && this.range.end === null) {
        return "All time";
      }

      if (differenceInDays(this.range.end, this.range.start) <= 1) {
        return moment(this.range.start).format("DD-MM-YY");
      }

      return `${moment(this.range.start).format("DD-MM-YY")} to ${moment(
        this.range.end
      ).format("DD-MM-YY")}`;
    }
  },
  async mounted() {
    this.allResponses = cloneDeep(this.response);
  },
  watch: {
    selectedIndex: {
      immediate: true,
      handler(i) {
        this.responses = cloneDeep(this.responseByIndex(i - 1));
        if (i == 0) {
          this.responses = cloneDeep(this.responseByIndex(0));
        }
        if (i > this.response.length) {
          this.responses = cloneDeep(
            this.responseByIndex(this.response.length - 1)
          );
        }
      }
    },
    filterOption: {
      immediate: true,
      handler(value) {
        switch (value) {
          case "all-time":
            this.range = {
              start: null,
              end: null
            };
            break;
          case "today":
            this.range = {
              start: startOfDay(new Date()),
              end: endOfDay(new Date())
            };
            break;
          case "seven-days":
            this.range = {
              start: new Date(subDays(new Date(), 7)),
              end: new Date()
            };
            break;
          case "fourteen-days":
            this.range = {
              start: new Date(subDays(new Date(), 14)),
              end: new Date()
            };
            break;

          default:
            break;
        }
      }
    },
    range: {
      immediate: true,
      deep: true,
      async handler(value) {
        if (value.start !== null || value.end !== null) {
          this.filterOption = "custom";
        } else {
          this.allResponses = cloneDeep(this.response);
        }
      }
    }
  },
  methods: {
    ...mapActions("form", ["getFormResponse", "setSelectedVersion", "getForm"]),

    nextIndex() {
      if (this.selectedIndex === this.allResponses.length) return;
      this.selectedIndex = this.selectedIndex + 1;
    },
    previousIndex() {
      if (this.selectedIndex === 1) return;
      this.selectedIndex = this.selectedIndex - 1;
    },
    isCorrectAnswer(type, answer, correctAnswer) {
      if (type === "TrueFalseType") {
        return answer === correctAnswer;
      }
      if (type === "MultipleChoiceQuizType") {
        return correctAnswer.some(r => answer.includes(r));
      }
    },
    isFilterApplied(value) {
      return this.filterOption === value;
    },
    setResponseFilter() {
      if (this.range.start && this.range.end) {
        const responses = cloneDeep(this.response);
        const result = responses.filter(
          response =>
            response.data.createdAt > this.range.start.getTime() &&
            response.data.createdAt < this.range.end.getTime()
        );
        this.allResponses = result;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.w-536 {
  max-width: 536px;
  margin: auto;
}

.total-point {
  background: #e8f1fb;
  border: 0.5px solid #166ed8;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
  min-height: 40px;
  max-width: 754px;
  padding: 10px;
  text-align: center;
}

.date-selector {
  width: 187px;
  padding: 6px 12px;
  border: solid 1px #a1aeb7;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  position: relative;

  &.active {
    border: solid 1px #166ed8;
  }

  .date-range {
    position: absolute;
    top: 40px;
    left: -112px;
    height: 328px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    z-index: 2;
    background: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14),
      0px 3px 14px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.2);
    .days-selector {
      width: 118px;
      border-right: 1px solid #e6e9ed;
      padding: 12px 0px;

      div {
        cursor: pointer;
        padding: 4px 20px;
        font-size: 14px;
        &.active {
          background: #edeff2;
        }
      }
    }

    .calendar-footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 12px;
    }
  }
}
</style>
