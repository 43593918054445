import cloneDeep from "lodash/cloneDeep";
import { startNodeId } from "@/constants/reservedNodeIds";

export default oriFields => {
  const fields = cloneDeep(oriFields);

  const fromToMappings = fields.reduce((acc, val) => {
    (val.logicJumps || []).forEach(element => {
      const map = [];
      map[0] = val.id;
      map[1] = element.to;
      acc.push(map);
    });

    return acc;
  }, []);

  let foundFirstNode = false;

  return fields.map(field => {
    const foundMap = fromToMappings.find(([, to]) => to === field.id);

    // if there is no parent id, then it is the first node in the node tree, assign "1" so that it will attach to the start node
    let parentId;
    if (foundMap) {
      parentId = foundMap[0];
    } else {
      parentId = foundFirstNode
        ? fields[fields.indexOf(field) - 1].id
        : startNodeId;
      foundFirstNode = true;
    }

    return {
      id: field.id,
      parentId,
      nodeComponent: "FlowyBlockNode",
      data: field
    };
  });
};
