<template>
  <b-col v-if="allFiles && allFiles.length > 0">
    <div
      class="upload-file p-3 mb-2"
      v-for="(file, index) in allFiles"
      :key="index"
      @mouseover="setHoverIndex(index)"
      @mouseleave="setHoverIndex(null)"
    >
      <div class="d-flex flex-row">
        <icon :name="determineIcon(file.fileType)" />
        <span class="ml-2">{{ file.fileName }}</span>
      </div>
      <div v-if="isActiveIndex(index)">
        <span style="cursor: pointer" @click="previewFile(file)">
          <b-icon
            v-if="showPreviewIcon(file.fileType)"
            style="margin-right: 12px;"
            icon="eye"
          />
        </span>
        <span style="cursor: pointer" @click="downloadFile(file)">
          <b-icon icon="download" />
        </span>
      </div>
    </div>
  </b-col>
  <b-col v-else>
    <div class="no-answer">No answer</div>
  </b-col>
</template>

<script>
export default {
  props: {
    allFiles: {
      type: Array
    }
  },
  data() {
    return {
      previewUrl: "",
      hoverIndex: null
    };
  },
  methods: {
    showPreviewIcon(type) {
      switch (type) {
        case "application/pdf":
          return true;

        case "image/jpg":
          return true;

        case "image/jpeg":
          return true;

        case "image/png":
          return true;

        case "image/gif":
          return true;

        case "video/mp4":
          return true;

        case "video/mov":
          return true;

        default:
          break;
      }
    },
    determineIcon(type) {
      switch (type) {
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return "word";

        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return "excel";

        case "application/pdf":
          return "pdf";

        case "image/jpeg":
          return "image";

        case "image/jpg":
          return "image";

        case "image/png":
          return "image";

        case "image/gif":
          return "image";

        case "video/mp4":
          return "video";

        case "video/mov":
          return "video";

        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          return "ppt";

        default:
          return "file";
      }
    },
    previewFile(file) {
      const previewFileKey = file.key;
      if (!previewFileKey) {
        return false;
      }
      window.open(
        `https://${process.env.VUE_APP_BUCKET_NAME}.s3.${process.env.VUE_APP_AWS_REGION}.amazonaws.com/${previewFileKey}`,
        "_blank"
      );
    },
    downloadFile(file) {
      const fileUrl = `https://${process.env.VUE_APP_BUCKET_NAME}.s3.${process.env.VUE_APP_AWS_REGION}.amazonaws.com/${file.key}`;

      window.URL = window.URL || window.webkitURL;

      let xhr = new XMLHttpRequest(),
        a = document.createElement("a"),
        fileRes;

      xhr.open("GET", fileUrl, true);
      xhr.responseType = "blob";
      xhr.onload = function() {
        fileRes = new Blob([xhr.response], {
          type: "application/octet-stream"
        });
        a.href = window.URL.createObjectURL(fileRes);
        a.download = file.fileName;
        a.click();
      };
      xhr.send();
    },

    setHoverIndex(value) {
      this.hoverIndex = value;
    },

    isActiveIndex(index) {
      return this.hoverIndex === index;
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-file {
  background: #edeff2;
  padding: 6px 12px !important;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  display: flex;
  border: 1px solid #edeff2;

  &:active {
    background: #e8f1fb;
    border: 1px solid #166ed8;
  }

  &:hover {
    background: #e8f1fb;
    border: 1px solid #166ed8;
  }
}
</style>
