<template>
  <section class="mb-5">
    <div class="p-4">
      <h5 class="mb-0">{{ $t("theme") }}</h5>
    </div>
    <template v-if="design">
      <ColorSelector
        :title="$t('titleColor')"
        :selectedColor.sync="design.colors.titleColor"
        :colorList="design.colorList.titleColor"
        @update:selectedColor="updateDesign"
        @add-color-list="addToColorList($event, 'titleColor')"
      />
      <ColorSelector
        :title="$t('descriptionColor')"
        :selectedColor.sync="design.colors.descriptionColor"
        :colorList="design.colorList.descriptionColor"
        @update:selectedColor="updateDesign"
        @add-color-list="addToColorList($event, 'descriptionColor')"
      />
      <ColorSelector
        :title="$t('answerColor')"
        :selectedColor.sync="design.colors.answerColor"
        :colorList="design.colorList.answerColor"
        @update:selectedColor="updateDesign"
        @add-color-list="addToColorList($event, 'answerColor')"
      />
      <ColorSelector
        :title="$t('buttonColor')"
        :selectedColor.sync="design.colors.buttonColor"
        :colorList="design.colorList.buttonColor"
        @update:selectedColor="updateDesign"
        @add-color-list="addToColorList($event, 'buttonColor')"
      />
      <ColorSelector
        :title="$t('buttonTextColor')"
        :selectedColor.sync="design.colors.buttonTextColor"
        :colorList="design.colorList.buttonTextColor"
        @update:selectedColor="updateDesign"
        @add-color-list="addToColorList($event, 'buttonTextColor')"
      />
      <ColorSelector
        :title="$t('backgroundColor')"
        :selectedColor.sync="design.colors.backgroundColor"
        :colorList="design.colorList.backgroundColor"
        @update:selectedColor="updateDesign"
        @add-color-list="addToColorList($event, 'backgroundColor')"
      />
      <div class="d-flex flex-row preview-wrapper">
        <div class="loading-wrapper" v-if="isLoading">
          <b-icon-arrow-clockwise animation="spin" />
        </div>
        <div id="preview" :class="{ active: useBackground }">
          <div v-if="url">
            <icon v-if="useBackground" name="check-circle" class="check-icon" />
            <b-img :src="url" @click="toggleUseBackground" />
          </div>
        </div>
        <div class="d-flex flex-column justify-content-center ml-2">
          <div v-if="fileName" style="padding: 6px 12px;">
            {{ fileName }}
          </div>
          <div style="padding: 0px 6px">
            <div class="input-wrapper">
              <div>Upload Image</div>
              <input type="file" class="input-file" @change="onFileChange" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<i18n>
{
  "en": {
   "version": "Version",
   "questionColor": "Question Color",
   "answerColor": "Answer Color",
   "buttonColor": "Button Color",
   "backgroundColor": "Background Color",
   "save": "Save",
   "buttonTextColor" : "Button Text Color",
   "titleColor" : "Title Color",
   "descriptionColor": "Description Color",
   "theme": "Theme"
  },
  "id": {
    "version": "Versi",
    "questionColor": "Warna Deskripsi",
    "answerColor": "Warna Jawaban",
    "buttonColor": "Warna Tombol",
    "backgroundColor": "Warna Background",
    "save": "Menyimpan",
    "buttonTextColor" : "Warna Tulisan Tombol",
    "titleColor" : "Warna Judul",
    "descriptionColor": "Warna Deskripsi",
    "theme": "Tema"
  }
}
</i18n>

<script>
import { mapActions, mapGetters } from "vuex";
import { cloneDeep } from "lodash";
import ColorSelector from "../common/ColorSelector";
import { Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    ColorSelector
  },
  data() {
    return {
      design: {},
      title: "",
      url: null,
      fileName: null,
      useBackground: false,
      image: null,
      isLoading: false
    };
  },
  computed: {
    ...mapGetters("form", ["form", "forms", "versions", "selectedVersion"]),
    versionOptions() {
      return this.versions.map(v => ({ value: v, text: v }));
    }
  },
  methods: {
    ...mapActions("form", ["setSelectedVersion", "updateFormDesign"]),
    updateDesign() {
      this.$emit("update-design", this.design);
    },
    addToColorList(event, type) {
      this.design.colorList[type].push(event);
      this.updateDesign();
    },
    async onFileChange(e) {
      this.image = e.target.files[0];

      const key = `${uuidv4()}.${this.image.type.split("/")[1]}`;
      try {
        this.isLoading = true;
        if (this.url) {
          await Storage.remove(this.url.split("/")[4]);
        }
        const result = await Storage.put(key, this.image, {
          contentType: this.image.type
        });
        this.$emit("update-background", {
          imageUrl: this.buildImageLink(result.key),
          fileName: this.image.name,
          useBackground: true
        });
        this.isLoading = false;
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    toggleUseBackground() {
      this.$emit("update-background", {
        imageUrl: this.url,
        fileName: this.fileName,
        useBackground: !this.useBackground
      });
    },
    buildImageLink(key) {
      return `https://s3.${process.env.VUE_APP_AWS_REGION}.amazonaws.com/${process.env.VUE_APP_BUCKET_NAME}/public/${key}`;
    }
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler() {
        const form = cloneDeep(this.form);
        if (form) {
          this.design = form.design;
          this.title = form.title;
          this.url = form.backgroundUrl;
          this.fileName = form.backgroundName;
          this.useBackground = form.useBackground;
        }
      }
    }
  }
};
</script>

<style scoped>
.mw-38 {
  max-width: 38px;
}

#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  flex-basis: 50%;
  border-radius: 5px;
}

#preview.active {
  border: 3px solid #007bff;
}

#preview img {
  width: 100%;
  height: auto;
}

#preview .check-icon {
  position: absolute;
  top: 8px;
  left: 8px;
}

.input-wrapper {
  position: relative;
  background: #166ed8;
  color: white;
  padding: 6px 12px;
  width: 123px;
  height: 36px;
  border-radius: 4px;
}

.input-wrapper p {
  margin: auto;
}

.input-wrapper input[type="file" i] {
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.input-file {
  width: 100%;
  background-color: #f4f5f6;
  padding: 6px 12px;
  position: relative;
}

input#file-upload-button:hover {
  cursor: pointer;
}

.preview-wrapper {
  padding: 16px 24px;
  position: relative;
}

.loading-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(207, 212, 218, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
</style>
