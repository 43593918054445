<template>
  <div>
    <p>{{ $t("shareLinks") }}</p>
    <div class="mb-2">
      <span>QR Code</span>
      <b-form-checkbox
        v-model="shareLinks.qr"
        name="required"
        :class="'float-right'"
      >
      </b-form-checkbox>
    </div>
    <div class="mb-2">
      <span>Facebook</span>
      <b-form-checkbox
        v-model="shareLinks.fb"
        name="required"
        :class="'float-right'"
      >
      </b-form-checkbox>
    </div>
    <div class="mb-2">
      <span>WhatsApp</span>
      <b-form-checkbox
        v-model="shareLinks.wa"
        name="required"
        :class="'float-right'"
      >
      </b-form-checkbox>
    </div>
    <div class="mb-2">
      <span>Embbed Script</span>
      <b-form-checkbox
        v-model="shareLinks.es"
        name="required"
        :class="'float-right'"
      >
      </b-form-checkbox>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "shareLinks": "Share links"
  },
  "id": {
    "shareLinks": "Bagikan tautan"
  }
}
</i18n>

<script>
export default {
  props: {
    shareLinks: {
      type: Object
    }
  }
};
</script>
