<template>
  <div v-if="answers.length > 0 && answers[0].answer !== ''">
    <b-col
      md="auto"
      class="d-flex flex-row p-0"
      v-for="(answer, index) of answers"
      :key="index"
    >
      <div class="telephone-text d-flex align-items-center p-3 mb-2 mr-1">
        <country-flag :country="answer.selectedCountry.alpha2" />
        <span class="text ml-2">{{
          answer.selectedCountry.countryCallingCodes
        }}</span>
      </div>
      <div class="telephone-text d-flex align-items-center p-3 mb-2">
        {{ answer.answer }}
      </div>
    </b-col>
  </div>
  <b-col v-else>
    <div class="no-answer">No answer</div>
  </b-col>
</template>

<script>
import CountryFlag from "vue-country-flag";

export default {
  components: {
    CountryFlag
  },
  props: {
    answers: {
      type: Array
    }
  }
};
</script>

<style lang="scss" scoped>
.telephone-text {
  background: #edeff2;
  padding: 6px 12px !important;
  border-radius: 4px;
}
</style>
