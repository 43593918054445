<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row" style="min-width: 536px;">
      <b-col class="p-0 mr-2" style="width:260px;">
        <b-row
          no-gutters
          v-for="(option, index) in oddOptions"
          :key="index"
          class="mb-2"
        >
          <b-col>
            <b-form-group class="mb-0">
              <div
                class="choice-option quiz"
                :class="{
                  'correct-answer-option': isCorrectAnswer(option.value),
                  'wrong-answer-option': isIncorrectAnswer(option.value),
                  checked: isTheAnswer(option.value)
                }"
              >
                <b-form-checkbox :checked="shouldChecked(option.value)">
                  {{ option.label }}
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="p-0" style="width:260px;">
        <b-row
          no-gutters
          v-for="(option, index) in evenOptions"
          :key="index"
          class="mb-2"
        >
          <b-col>
            <b-form-group class="mb-0">
              <div
                class="choice-option quiz"
                :class="{
                  'correct-answer-option': isCorrectAnswer(option.value),
                  'wrong-answer-option': isIncorrectAnswer(option.value),
                  checked: isTheAnswer(option.value)
                }"
              >
                <b-form-checkbox :checked="shouldChecked(option.value)">
                  {{ option.label }}
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </div>
    <div>
      <span v-if="!isSingleAnswerCorrect" class="text-description"
        >{{ $t("correctAnswer") }} "{{
          correctAnswer === "a" ? "True" : "False"
        }}"</span
      >
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "correctAnswer": "Correct answer are"
  },
  "id": {
    "correctAnswer": "Jawaban benar adalah"
  }
}
</i18n>

<script>
export default {
  props: {
    answers: {
      type: Array
    },
    correctAnswer: {
      type: String
    }
  },
  data() {
    return {
      options: [
        { label: "True", value: "a" },
        { label: "False", value: "b" }
      ]
    };
  },
  computed: {
    evenOptions() {
      return this.options.filter((item, index) => index % 2);
    },
    oddOptions() {
      return this.options.filter((item, index) => !(index % 2));
    },
    isSingleAnswerCorrect() {
      let answer = "";
      if (this.singleAnswer === this.correctAnswer) {
        answer = this.correctAnswer;
      }
      return answer;
    }
  },
  methods: {
    shouldChecked(value) {
      if (this.answers[0].answer === this.correctAnswer) {
        return value === this.correctAnswer;
      } else {
        return true;
      }
    },
    isCorrectAnswer(value) {
      if (this.answers[0].answer === this.correctAnswer) {
        return value === this.correctAnswer;
      }
      return false;
    },
    isIncorrectAnswer(value) {
      return (
        this.answers[0].answer !== this.correctAnswer &&
        value !== this.correctAnswer
      );
    },
    isTheAnswer(value) {
      return this.answers[0].answer.includes(value);
    }
  }
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: #3881d7 !important;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.selected-wrapper {
  border: 1px solid #3881d7 !important;
}

.choice-option {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 200px;
  padding: 6px 12px;
  border: 1px solid #d9dde1;
  color: #5e6674;
  border-radius: 4px;

  .bullet {
    width: 16px;
    height: 16px;
    background-color: #e8f1fb;
    color: #5e6674;
    border: 1px solid #5e6674;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .choice-label {
    margin-left: 8px;
  }

  .custom-control {
    padding-left: 0px;
  }
}
</style>
