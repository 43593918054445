<template>
  <div class="w-5 bg-white d-flex flex-column  border-right">
    <div
      :style="activeStepGuide === 0 ? 'z-index: 99999' : ''"
      @click.prevent="showFlowDrag"
      :class="isShowFlowDrag ? 'button-primary' : 'button-light'"
      class="rounded-0 w-48"
    >
      <b-icon icon="file-text" font-scale="1.5"></b-icon>
    </div>
    <div
      id="form-style"
      @click.prevent="showFormStyleEditor"
      :class="isShowFormStyleEditor ? 'button-primary' : 'button-light'"
      class="rounded-0 w-48"
    >
      <b-icon icon="brush" font-scale="1.5"></b-icon>
    </div>
    <div
      id="form-setting"
      @click.prevent="showFormResponseSetting"
      :class="isShowFormResponseSetting ? 'button-primary' : 'button-light'"
      class="rounded-0 w-48"
    >
      <b-icon icon="gear" font-scale="1.5"></b-icon>
    </div>

    <div class="mt-auto rounded-0 w-48 btn" id="help-center">
      <b-icon
        style="color: #166ED8;"
        icon="question-circle"
        font-scale="1.5"
      ></b-icon>
    </div>
    <b-popover
      variant="info"
      target="help-center"
      triggers="click"
      placement="top"
      id="popover-help"
      ref="popover2"
    >
      <div class="help-center">
        <span class="mb-1 cursor-pointer"
          ><a href="http://www.survein.com/faq.html" target="_blank">{{
            $t("help")
          }}</a></span
        >
        <span @click="showTourGuide()" class="cursor-pointer">{{
          $t("features")
        }}</span>
      </div>
    </b-popover>
  </div>
</template>

<i18n>
{
  "en": {
    "help": "Help Center",
    "features": "Features onboarding"
  
  },
  "id": {
    "help": "Pusat Bantuan",
    "features": "Fitur onboarding"
   
  }
}
</i18n>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    isShowFormStyleEditor: {
      type: Boolean
    },
    isShowFormBuilderFormField: {
      type: Boolean
    },
    isShowFlowDrag: {
      type: Boolean
    },
    isShowFormResponseSetting: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters("form", ["activeStepGuide"])
  },
  methods: {
    showFormStyleEditor() {
      this.$emit("show-form-style-editor");
    },
    showFormBuilderFormField() {
      this.$emit("show-form-builder-form-field");
    },
    showFlowDrag() {
      this.$emit("show-flow-drag");
    },
    showFormResponseSetting() {
      this.$emit("show-form-response-setting");
    },
    showTourGuide() {
      this.$root.$emit("bv::hide::popover", "popover-help");
      this.$emit("show-tour-guide");
    }
  }
};
</script>

<style lang="scss" scoped>
.w-48 {
  width: 48px;
  height: 48px;
}

.button-primary {
  padding: 12px;
  background: #e8f1fb;
  color: #166ed8;
  cursor: pointer;
}

.button-light {
  padding: 12px;
  color: #5e6674;
  cursor: pointer;
}

.help-center {
  display: flex;
  flex-direction: column;
  padding: 0px 8px 0px 8px;
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
</style>
