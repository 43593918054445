import { render, staticRenderFns } from "./FlowyBlockPreview.vue?vue&type=template&id=56cee89a&scoped=true&"
import script from "./FlowyBlockPreview.vue?vue&type=script&lang=js&"
export * from "./FlowyBlockPreview.vue?vue&type=script&lang=js&"
import style0 from "./FlowyBlockPreview.vue?vue&type=style&index=0&id=56cee89a&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56cee89a",
  null
  
)

/* custom blocks */
import block0 from "@/locales/common/form-field-type-names.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fcodebuild%2Foutput%2Fsrc562417754%2Fsrc%2Fgithub.com%2Fsurvein%2Fsurvein-admin%2Fsrc%2Fcomponents%2Fcommon%2FFlowyBlockPreview.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports