<template>
  <div>
    <div
      v-if="!responses.length"
      class="d-flex justify-content-center align-items-center flex-column"
      style="margin-top: 130px"
    >
      <icon name="no-response" />
      <h4>{{ $t("noResponseYet") }}</h4>
      <span style="width: 350px; text-align: center">{{
        $t("publishYourForm")
      }}</span>
    </div>

    <b-row v-else>
      <b-col>
        <b-card
          style="min-height: 256px; max-height:430px; maxWidth:754px"
          class="ml-auto mr-auto mb-2 card-border"
          v-for="field in fields"
          :key="field.id"
        >
          <b-row no-gutters class="mt-3">
            <b-col
              v-if="
                field.type !== 'MULTISELECT_QUIZ' && field.type !== 'TRUE_FALSE'
              "
              cols="9"
              class="m-auto"
            >
              <h5>{{ field.title }}</h5>
              <h5 v-if="!field.title">...</h5>
              <span v-html="field.description" class="text-description"></span>
            </b-col>
            <b-col
              v-if="
                field.type === 'MULTISELECT_QUIZ' || field.type === 'TRUE_FALSE'
              "
              cols="9"
              class="m-auto"
            >
              <h5 v-html="field.description" class="m-0"></h5>
            </b-col>
          </b-row>
          <b-row no-gutters class="mt-2 mb-2 w-536">
            <component
              :is="field.type"
              :answers="getAnswer(field.id)"
              :options="field.options"
              :allFiles="getFiles(field.id)"
            />
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<i18n>
{
  "en": {
    "noResponseYet": "No responses yet",
    "publishYourForm": "Publish the form & share to your circle, and you will see their responses here.",
    "respondent": "Respondent no."
  },
  "id": {
     "noResponseYet": "Belum mendapatkan tanggapan",
     "publishYourForm": "Publikasikan formulir & bagikan ke sekitar Anda, dan Anda akan melihat tanggapan mereka di sini.",
     "respondent": "Responden no."
  }
}
</i18n>

<script>
import { mapGetters, mapActions } from "vuex";
import { cloneDeep } from "lodash";
import START_PAGE from "@/components/FormResponse/Summary/StartPage";
import EMAIL from "@/components/FormResponse/Summary/Email";
import TEL from "@/components/FormResponse/Summary/Telephone";
import SCALE from "@/components/FormResponse/Summary/Scale";
import LONG_TEXT from "@/components/FormResponse/Summary/LongText";
import SHORT_TEXT from "@/components/FormResponse/Summary/ShortText";
import DROPDOWN from "@/components/FormResponse/Summary/Dropdown";
import DATE from "@/components/FormResponse/Summary/Date";
import STATEMENT from "@/components/FormResponse/Summary/Statement";
import MULTISELECT from "@/components/FormResponse/Summary/Multiple";
import NUMBER from "@/components/FormResponse/Summary/Number";
import FILE_UPLOAD from "@/components/FormResponse/Summary/UploadFile";
import MULTISELECT_QUIZ from "@/components/FormResponse/Summary/MultipleQuiz";
import TRUE_FALSE from "@/components/FormResponse/Summary/TrueFalse";

export default {
  components: {
    START_PAGE,
    EMAIL,
    TEL,
    SCALE,
    LONG_TEXT,
    SHORT_TEXT,
    DROPDOWN,
    DATE,
    STATEMENT,
    MULTISELECT,
    NUMBER,
    FILE_UPLOAD,
    MULTISELECT_QUIZ,
    TRUE_FALSE
  },
  props: {
    teamId: {
      type: String,
      required: true
    },
    workspaceId: {
      type: String,
      required: true
    },
    formId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      fields: [],
      responses: []
    };
  },
  computed: {
    ...mapGetters("form", ["form", "forms", "selectedVersion", "response"])
  },
  mounted() {
    this.getAllResponses();
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler() {
        this.selectedForm = cloneDeep(this.form);
        this.fields = this.selectedForm.fields;
      }
    }
  },
  methods: {
    ...mapActions("form", ["getFormResponse", "setSelectedVersion", "getForm"]),
    getAllResponses() {
      for (const singleResponse of this.response) {
        this.responses = [...this.responses, ...singleResponse.data.responses];
      }
    },
    getAnswer(fieldId) {
      const answers = this.responses.filter(
        response => response.id === fieldId
      );
      return answers.filter(answer => answer.answer !== "");
    },
    getFiles(fieldId) {
      const uploadFileResponse = this.responses.filter(
        response => response.id === fieldId
      );
      let files = [];
      for (let i = 0; i <= uploadFileResponse.length - 1; i++) {
        if (uploadFileResponse[i].files != null) {
          files = [...files, ...uploadFileResponse[i].files];
        }
      }
      return files;
    }
  }
};
</script>

<style lang="scss" scoped>
.w-536 {
  max-height: 310px;
  overflow-y: auto;
  max-width: 536px;
  margin: auto;
}

.card-body {
  overflow-y: auto;
}
</style>
