<template>
  <div v-if="answers.length > 0 && answers[0].answer !== ''">
    <b-col
      md="auto"
      class="d-flex flex-row p-0"
      v-for="(answer, index) in answers"
      :key="index"
    >
      <div class="date-text p-2 mb-2 mr-1">
        {{ day(answer.answer) }}
      </div>
      <div class="date-text p-2 mb-2 mr-1">
        {{ month(answer.answer) }}
      </div>
      <div class="date-text p-2 mb-2 mr-1">
        {{ year(answer.answer) }}
      </div>
    </b-col>
  </div>
  <b-col v-else>
    <div class="no-answer">No answer</div>
  </b-col>
</template>

<script>
import moment from "moment";

export default {
  props: {
    answers: {
      type: Array
    }
  },
  methods: {
    day(date) {
      return moment(date).format("DD");
    },
    month(date) {
      return moment(date).format("MMM");
    },
    year(date) {
      return moment(date).format("YYYY");
    }
  }
};
</script>

<style lang="scss" scoped>
.date-text {
  background: #edeff2;
  border-radius: 4px;
  width: 66px;
  text-align: center;
}
</style>
